import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b4d04cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper collapse mt-3 px-4 py-3 rounded" }
const _hoisted_2 = { class: "filter-col" }
const _hoisted_3 = { class: "filter-col" }
const _hoisted_4 = { class: "filter-col" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass($setup.rowClasses)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["SortFilter"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["FileTypeFilter"])
      ]),
      _createVNode($setup["DateRangeFilter"], { class: "filter-col" }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["PerPageFilter"])
      ])
    ], 2 /* CLASS */)
  ]))
}
<template>
  <div>
    <label :class="['form-label mb-1', labelLg && 'label-lg']" :for="textarea.id">{{
      label
    }}</label>
    <textarea
      v-uid
      ref="textarea"
      class="form-control"
      :aria-label="ariaLabel"
      @change.trim="$emit('change', textarea.value)"
      >{{ value }}</textarea
    >
  </div> </template
>;

<script setup>
import { ref } from "vue";

const props = defineProps({
  label: String,
  ariaLabel: String,
  value: String,
  labelLg: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["change"]);

const textarea = ref({});
</script>

<style scoped>
.form-label {
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
}
.label-lg {
  font-size: 0.95rem;
}
</style>

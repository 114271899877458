import { vModelText as _vModelText, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c8cb7e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group w-sm-75 max-600 me-auto" }
const _hoisted_2 = ["onKeydown"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["aria-expanded"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "form-control",
      name: "search",
      placeholder: "Search...",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.query) = $event)),
      onKeydown: _withModifiers($setup.submitOnEnter, ["stop"])
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [
      [
        _vModelText,
        $setup.query,
        void 0,
        { trim: true }
      ]
    ]),
    _createElementVNode("button", {
      class: _normalizeClass(['btn btn-outline-primary', $setup.uiState.global && 'form-control']),
      type: "submit",
      onClick: _withModifiers($setup.submitQuery, ["prevent"])
    }, [
      _createVNode($setup["SearchIcon"])
    ], 10 /* CLASS, PROPS */, _hoisted_3),
    (!$setup.uiState.global)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          id: "filters-toggle",
          "aria-expanded": $setup.visible,
          "aria-controls": "filters-pane",
          class: "btn btn-outline-primary",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#filters-pane",
          onClick: $setup.toggleVisible
        }, [
          _createVNode($setup["FilterIcon"])
        ], 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ]))
}
<template>
  <div class="form-check form-switch">
    <input
      v-uid
      ref="input"
      class="form-check-input switch"
      type="checkbox"
      role="switch"
      :checked="checked"
      @change="$emit('change', input.checked)"
    />
    <label class="form-check-label" :for="input.id">{{ label }}</label>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
const props = defineProps({
  label: String,
  ariaLabel: String,
  checked: [String, Boolean],
});

defineEmits(["change"]);

const input = ref({});

onMounted(() => {
  if (props.focusOnMount) {
    input.value.focus();
  }
});
</script>

<style scoped>
.form-switch {
  display: flex;
  gap: 15px;
  padding-left: 0;
  align-items: center;
}

.form-check-input.switch {
  width: 50px;
  height: 30px;
  margin-left: 0;
  flex-shrink: 0;
  margin-top: 0;
}
</style>

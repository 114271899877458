import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classNames)
  }, [
    _createElementVNode("h3", null, _toDisplayString($setup.props.header), 1 /* TEXT */),
    ($props.settings && $props.settings.length)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: _normalizeClass($setup.buttonClasses),
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('showSettings')))
        }, [
          _createVNode($setup["SettingsIcon"])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
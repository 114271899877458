<template>
  <div class="error-body">
    <p>Sorry! There was an error loading this widget:</p>
    <p>
      Widget:
      <span class="text-danger">{{ props.name }}</span>
    </p>
    <p>Error detail: {{ message }}</p>
    <p>
      If you don't need this widget, you can simply delete it from your layout. It won't reappear.
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  name: String,
  error: String,
});

const message = computed(() => {
  if (props.error === "{}") return "No info available.";
  return props.error;
});
</script>

<style scoped>
.error-body {
  padding-block-start: 20px;
}
</style>

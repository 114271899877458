<template>
  <Modal title="New Comment Reply" id="replyCommentModal">
    <div class="modal-body">
      <div class="form-group">
        <label><strong>Their Comment</strong></label>
        <p v-html="parentComment.html"></p>
      </div>
      <div class="form-group">
        <label><strong>Your Reply</strong></label>
        <textarea rows="3" class="form-control" v-model="replyComment.comment"></textarea>
      </div>
      <div v-if="showPrivate && !parentComment.private" class="form-group form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="replyPrivate"
          v-model="replyComment.private"
        />
        <label class="form-check-label" for="replyPrivate"
          >Make this an internal comment? It will be viewable by SFCTA staff only.</label
        >
      </div>
      <div v-if="showCommentEmail" class="form-group form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="send_email"
          v-model="replyComment.send_email"
        />
        <label class="form-check-label" for="send_email"
          >Email this comment to project staff and subscribers?</label
        >
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
      <button
        type="button"
        class="btn btn-success"
        v-on:click="postReply"
        v-bind:disabled="replyComment.comment === ''"
      >
        Post
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from "../shared/modal/modal.vue";

/*
This component defines a modal for replying to a comment, and on post, will emit
"post-comment", which uses the saame method as posting a new comment
*/
export default {
  components: {
    Modal,
  },
  props: {
    parentComment: Object,
    force_private: Boolean,
    force_comment_email: Boolean,
    user_is_staff: Boolean,
    tab: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      replyComment: {
        comment: "",
        private: this.force_private || false,
        send_email: this.force_comment_email || false,
      },
    };
  },
  watch: {
    parentComment(newValue) {
      this.resetReplyComment();
    },
  },
  computed: {
    showCommentEmail() {
      return this.force_comment_email == null;
    },
    showPrivate() {
      return this.user_is_staff && !this.parentComment.private && this.force_private == null;
    },
  },
  methods: {
    postReply() {
      const { indexPath } = this.parentComment;

      // set reply comment default sort to end of list
      if (this.parentComment) {
        this.replyComment.sort = this.parentComment.replies.length;
      }

      indexPath.push(this.replyComment.sort);

      this.$emit("post-comment", {
        comment: {
          parent_id: this.parentComment.id,
          comment: this.replyComment.comment,
          tab_placement: this.tab,
          // if the parent comment is private, the replies should be as well
          private: this.replyComment.private || this.parentComment.private,
          send_email: this.replyComment.send_email,
        },
        indexPath,
      });
      this.replyComment.comment = "";

      bootstrap.Modal.getInstance(document.querySelector("#replyCommentModal")).hide();
    },
    resetReplyComment() {
      this.replyComment.comment = "";
      this.replyComment.private = this.force_private || false;
      this.replyComment.send_email = this.force_comment_email || false;
    },
  },
  template: `

  `,
};
</script>

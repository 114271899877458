import FuzzySearch from "fuzzy-search";

export default class NoiceSelectHelper {

  initialize() {
    this.createNoiceSelectsIn(document);
    this.bindListeners();
  }

  bindListeners() {
    document.addEventListener('cocoon:after-insert', this.handleCocoonInsert.bind(this));
  }

  handleCocoonInsert(event) {
    const newContent = event.detail && event.detail[2];
    this.createNoiceSelectsIn(newContent);
  }

  createNoiceSelectsIn(container) {
    container.querySelectorAll("[data-noice-select]").forEach((node) => {
      NoiceSelect.fetchOrCreate(node);
    });
  }

}


/**
* @class NoiceSelect
* Creates a very noice select dropdown using Bootstrap 5 classes and tools
* Inspired by bluzky's vanilla JS nice-select2 library
* https://github.com/bluzky/nice-select2/tree/v1.0.0
*/
export class NoiceSelect {

  static instances = [];

  /**
  * @static
  * Fetches the existing instance for a select
  * @param {element} select - The select for which to find an existing instance
  * @return {NoiceSelect, undefined} - the found NoiceSelect instance
  */
  static fetch(select) {
    return NoiceSelect.instances.find(instance => instance.select === select);
  }

  /**
  * @static
  * Fetches the existing instance for a select, else creates a new one
  * @param {element} select - The select for which to find or create an instance
  * @param {object} options - Options object - see {@link constructor} (if new instance created)
  * @param {boolean} autocreate - If true, DOM elements are created automatically (if new instance created)
  * @return {NoiceSelect} - the found or created NoiceSelect instance
  */
  static fetchOrCreate(select, options = {}, autocreate = true) {
    NoiceSelect.fetch(select) || new NoiceSelect(select, options, autocreate);
  }

  /**
  * @static
  * Creates a new NoiceSelect for each element in the parent matching selector
  * @param {element} parent - The element to query for matching children
  * @param {object} options - Options object - see {@link constructor}
  * @param {string} selector - The CSS selector used to find select elements under parent
  * @return {array<NoiceSelect>} - the created NoiceSelect instances
  */
  static buildWithin(element, options = {}, selector = "[data-noice-select]") {
    return Array.from(element.querySelectorAll("[data-noice-select]")).map((target) => {
      new NoiceSelect(target, options);
    });
  }

  /**
  * Builds a new NoiceSelect, hiding the underlying select element
  * @constructor
  * @param {element} element - The select element to base on
  * @param {object} options - NoiceSelect options (can be overridden by data attrs on {element})
  * @param {boolean} options.multiple - Creates a multiple select (element must be select multiple)
  * @param {boolean} options.disabled - Disables the NoiceSelect by default
  * @param {boolean} options.search - Adds search box to dropdown to fuzzy-ily filter options
  * @param {boolean} options.mobile - Uses a native select instead of a dropdown; often better for mobile devices
  * @param {string} options.placeholder - Sets trigger button placeholder text
  * @param {string} options.selectedClass - The class to be applied to selected list items
  *
  * All options can be set on the select element itself, either as attributes or data attributes.
  * Boolean params will be treated as true if present unless they have the value "false".
  *
  * Options extracted from element attributes take precedence over options passed into the constructor,
  * which allows you to pass an object of defaults when constructing multiple and override per-element.
  */
  constructor(element, options = {}, autocreate = true) {
    if(!element.tagName == "select") {
      console.log(`NoiceSelect: invalid target element ${element.tagName}; must target a select element`); // eslint-disable-no-console
      return;
    } else {
      const existing = NoiceSelect.fetch(element);
      if(existing) {
        console.log(`NoiceSelect instance already exists for this ${element.tagName}; destroy the old instance first if you want to create a new one`); // eslint-disable-no-console
        return existing;
      }
    }

    this.select = element;
    this.config = this.generateConfig(options);
    if(this.config.search) {
      const searchOptions = Array.from(this.select.options).map((opt) => opt.textContent)
      this.fuzzySearch = new FuzzySearch(searchOptions, [], { sort: true })
    }

    if(autocreate) this.create();

    NoiceSelect.instances.push(this);
  }

  /**
  * Creates the NoiceSelect DOM elements and binds event listeners.
  */
  create() {
    if(this.config.mobile) {
      this.renderMobileDropdown();
    } else {
      this.select.classList.add("d-none");

      this.renderDropdown();
      this.bindListeners();
    }
  };

  /**
  * Binds event listeners for list item clicks and, if enabled, search input.
  */
  bindListeners() {
    this.wrapper.addEventListener("click", this.handleItemClick);

    if(this.config.search) {
      this.searchInput.addEventListener("input", this.handleSearchInput);
      this.trigger.addEventListener("shown.bs.dropdown", this.focusSearch);
      this.trigger.addEventListener("hidden.bs.dropdown", this.clearSearch);
    }

    if(this.config.multiple) {
      this.deselectAllButton.addEventListener("click", this.deselectAll);
    }
  }

  // Event Listeners

  /**
  * @callback handleItemClick
  * Handles selecting and deselecting options based on dropdown list item mouse clicks
  * @param {Event} event
  */
  handleItemClick = event => {
    if(!event.target.matches("li[data-value]")) return;

    const value = event.target.dataset.value;
    const option = this.select.querySelector(`[value='${value}']`);

    if(!option) return;

    if(this.config.multiple) {
      option.selected = !option.selected;
      event.target.classList.toggle(this.config.selectedClass);
      event.target.classList.toggle("selected");
    } else {
      this.select.value = value;
      Array.from(this.itemList.children).forEach(node => node.classList.remove(this.config.selectedClass, "selected"));
      event.target.classList.add(this.config.selectedClass, "selected");
    }

    this.updateTriggerText();

    const changeEvent = new Event("change");
    this.select.dispatchEvent(changeEvent);
  }

  /**
  * @callback deselectAll
  * Clears all selected items
  */
  deselectAll = event => {
    // Deselect all selected options
    this.select.selectedIndex = -1;
    Array.from(this.select.options).forEach((opt) => {
      opt.selected = null;
    });

    this.updateListItems();
    this.updateTriggerText();
  }

  /**
  * @callback handleSearchInput
  * Handles input changes in the search text field, filtering results based on value
  * @param {Event} event
  */
  handleSearchInput = event => {
    let opts = [];

    if(event.target.value) {
      const result = this.fuzzySearch.search(event.target.value);

      opts = Array.from(this.select.options).filter((opt) => {
        return result.includes(opt.textContent);
      });

      if(opts.length) {
        this.searchInput.classList.remove("invalid");
        this.searchInput.classList.add("valid");
      } else {
        this.searchInput.classList.remove("valid");
        this.searchInput.classList.add("invalid");
      }
    } else {
      this.searchInput.classList.remove("valid", "invalid");
      opts = this.select.options;
    }

    this.updateListItems(opts);
  }

  /**
  * @callback focusSearch
  * Focuses the search input
  */
  focusSearch = () => {
    this.searchInput.focus();
  }

  /**
  * @callback clearSearch
  * Clears the value of the search input and resets visible list items
  */
  clearSearch = event => {
    this.searchInput.value = "";
    this.searchInput.dispatchEvent(new Event("input"));
  }

  // Renderers

  /**
  * If config.mobile, simply style the select but leave the native select
  */
  renderMobileDropdown() {
    this.select.classList.add("form-control");
  }

  renderDropdown() {
    var classes = [
      "noice-select",
      this.attr(this.select, "class") || "",
      this.config.disabled ? "disabled" : ""
    ];

    this.wrapper = this.createWrapper();
    this.trigger = this.createDropdownTrigger();
    this.dropdown = this.createDropdown();
    this.itemList = this.createDropdownItemList();
    if(this.config.search) this.searchInput = this.createSearch();
    if(this.config.multiple) this.deselectAllButton = this.createDeselectAllButton();

    this.select.insertAdjacentElement("afterend", this.wrapper);
    this.wrapper.appendChild(this.trigger);
    this.wrapper.appendChild(this.dropdown);
    if(this.config.search) this.dropdown.appendChild(this.searchInput);
    if(this.config.multiple) this.dropdown.appendChild(this.deselectAllButton);
    this.dropdown.appendChild(this.itemList);

    this.updateTriggerText();
    this.updateListItems();
  };

  createWrapper() {
    const wrapper = document.createElement("div");
    wrapper.classList.add("noice-select");

    return wrapper;
  }

  createDropdownTrigger() {
    const button = document.createElement("button");
    button.type = "button";
    button.classList.add("form-control", "trigger", "text-start", "d-relative", "pe-5");
    button.dataset["bsToggle"] = "dropdown";
    button.dataset["boundary"] = "viewport"
    button.innerHTML = "Nothing selected...";

    if(this.config.multiple) {
      button.dataset["bsAutoClose"] = "outside";
    }

    return button;
  }

  createDropdown() {
    const dropdown = document.createElement("div");
    dropdown.classList.add("dropdown-menu", "p-0");

    return dropdown;
  }

  createDropdownItemList() {
    const itemList = document.createElement("ul");
    itemList.classList.add("list-group", "list-group-flush");

    return itemList;
  }

  createSearch() {
    const searchInput = document.createElement("input");
    searchInput.type = "text";
    searchInput.classList.add("form-control");
    searchInput.placeholder = "Search...";

    return searchInput;
  }

  updateTriggerText() {
    const items = Array.from(this.select.selectedOptions).map((item) => {
      if(!item.value) return;

      const span = document.createElement("span");

      if(this.config.multiple) {
        span.classList.add("badge");
        span.dataset.multiple = true;
      }

      span.innerHTML = item.textContent;
      return span;
    }).filter((item) => item);

    while(this.trigger.firstChild) {
      this.trigger.removeChild(this.trigger.firstChild);
    }

    if(items.length && items[0]) {
      items.forEach(item => {
        this.trigger.appendChild(item);
      });
    } else {
      const span = document.createElement("span");
      span.innerHTML = this.config.placeholder || "&nbsp;";
      span.classList.add("text-muted");
      this.trigger.appendChild(span);
    }
  };

  updateListItems(options = this.select.options) {
    while(this.itemList.firstChild) {
      this.itemList.removeChild(this.itemList.firstChild);
    }

    Array.from(options).forEach(item => {
      this.itemList.appendChild(this.renderListItem(item));
    });
  };

  renderListItem(option) {
    const li = document.createElement("li");
    li.dataset.value = option.value;

    const classList = [
      "list-group-item",
      "list-group-item-action",
      "pe-5",
      option.selected ? [this.config.selectedClass, "selected"]: null,
      option.disabled ? "noice-disabled" : null,
      ...option.classList
    ].flat().filter(cls => cls);

    li.classList.add(...classList);
    li.innerHTML = option.innerHTML || "&nbsp;";
    return li;
  };

  createDeselectAllButton() {
    const button = document.createElement("button");
    button.classList.add("btn", "btn-outline-dark", "btn-sm", "w-100");
    button.type = "button";
    button.innerHTML = "Deselect All"

    return button;
  }

  recreate() {
    if(this.wrapper) {
      const open = this.hasClass(this.dropdown, "show");
      this.destroy();
      this.create();

      if(open) {
        bootstrap.Dropdown.getInstance(this.dropdown).show();
      }
    }
  };

  disable() {
    if (!this.config.disabled) {
      this.config.disabled = true;
      this.addClass(this.trigger, "disabled");
    }
  };

  enable() {
    if (this.config.disabled) {
      this.config.disabled = false;
      this.removeClass(this.trigger, "disabled");
    }
  };

  clear() {
    this.selectedOptions = [];
    this._renderSelectedItems();
    this.updateSelectValue();
    this.triggerChange(this.select);
  };

  destroy() {
    if(this.wrapper) {
      this.wrapper.parentNode.removeChild(this.wrapper);
      this.select.classList.remove("d-none");
    }
  };

  _onKeyPressed(e) {
    // Keyboard events

    var focusedOption = this.dropdown.querySelector(".focus");

    var open = this.dropdown.classList.contains("open");

    // Space or Enter
    if (e.keyCode == 32 || e.keyCode == 13) {
      if (open) {
        this.this.triggerClick(focusedOption);
      } else {
        this.triggerClick(this.dropdown);
      }
    } else if (e.keyCode == 40) {
      // Down
      if (!open) {
        this.triggerClick(this.dropdown);
      } else {
        var next = this._findNext(focusedOption);
        if (next) {
          var t = this.dropdown.querySelector(".focus");
          this.removeClass(t, "focus");
          this.addClass(next, "focus");
        }
      }
      e.preventDefault();
    } else if (e.keyCode == 38) {
      // Up
      if (!open) {
        this.triggerClick(this.dropdown);
      } else {
        var prev = this._findPrev(focusedOption);
        if (prev) {
          var t = this.dropdown.querySelector(".focus");
          this.removeClass(t, "focus");
          this.addClass(prev, "focus");
        }
      }
      e.preventDefault();
    } else if (e.keyCode == 27 && open) {
      // Esc
      this.triggerClick(this.dropdown);
    }
    return false;
  };

  _findNext(el) {
    if (el) {
      el = el.nextElementSibling;
    } else {
      el = this.dropdown.querySelector(".list .option");
    }

    while (el) {
      if (!this.hasClass(el, "disabled") && el.style.display != "none") {
        return el;
      }
      el = el.nextElementSibling;
    }

    return null;
  };

  _findPrev(el) {
    if (el) {
      el = el.previousElementSibling;
    } else {
      el = this.dropdown.querySelector(".list .option:last-child");
    }

    while (el) {
      if (!this.hasClass(el, "disabled") && el.style.display != "none") {
        return el;
      }
      el = el.previousElementSibling;
    }

    return null;
  };

  triggerClick(el) {
    var event = document.createEvent("MouseEvents");
    event.initEvent("click", true, false);
    el.dispatchEvent(event);
  }

  triggerChange(el) {
    var event = document.createEvent("HTMLEvents");
    event.initEvent("change", true, false);
    el.dispatchEvent(event);
  }

  attr(el, key) {
    return el.getAttribute(key);
  }

  data(el, key) {
    return el.getAttribute("data-" + key);
  }

  hasClass(el, className) {
    if (el) return el.classList.contains(className);
    else return false;
  }

  addClass(el, className) {
    if (el) return el.classList.add(className);
  }

  removeClass(el, className) {
    if (el) return el.classList.remove(className);
  }

  get selectOptions() {
    Array.from(this.select.options);
  }

  generateConfig(options) {
    return Object.assign(this.defaultOptions, options, this.extractSelectAttributes());
  }

  extractSelectAttributes() {
    return Object.fromEntries(Object.entries(this.defaultOptions).map(([key, defaultVal]) => {
      const exists = this.select.hasAttribute(key) || (key in this.select.dataset);
      if(!exists) return;

      let result;
      const value = this.select.getAttribute(key) || this.select.dataset[key];

      switch(typeof defaultVal) {
        case "boolean":
          return [key, value !== "false"];
        case "string":
          return [key, value];
      }
    }).filter((a) => a));
  }

  get defaultOptions() {
    return {
      disabled: false,
      multiple: false,
      mobile: false,
      search: false,
      placeholder: "Select an Option",
      selectedClass: "list-group-item-primary"
    };
  }

}

/**
* @class PeopleEdit
* Page-specific script for people#edit
*/

export default class PeopleEdit {

  bindListeners() {
    SFCTA.DOM.listen("#token-generator", "click", this.handleTokenGeneration);
  }

  handleTokenGeneration = event => {
    document.querySelector("#user_api_token").value = this.guid();
  }

  guid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

}

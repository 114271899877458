import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f22905c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["aria-label", "value"]
const _hoisted_3 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_uid = _resolveDirective("uid")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: _normalizeClass(['form-label mb-1', $props.labelLg && 'label-lg']),
      for: $setup.select.id
    }, _toDisplayString($props.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1),
    _withDirectives((_openBlock(), _createElementBlock("select", {
      ref: "select",
      class: "form-select",
      "aria-label": $props.ariaLabel,
      value: $props.value,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('change', $setup.select.value)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: option.value,
          value: option.value
        }, _toDisplayString(option.label), 9 /* TEXT, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2)), [
      [_directive_uid]
    ])
  ]))
}
<template>
  <div class="signature row">
    <Transition name="fade" mode="out-in">
      <div v-if="confirmingDelete" class="signature-overlay">
        <button type="button" class="btn btn-danger" @click="deleteSignature">
          Confirm Delete
        </button>
        <button type="button" class="btn btn-light" @click="confirmingDelete = false">
          Cancel
        </button>
      </div>

      <div v-else-if="hover && userIsAdmin" class="signature-overlay">
        <button type="button" class="btn btn-danger" @click="confirmDelete">
          Delete Signature
        </button>
      </div>
    </Transition>

    <div class="col-12 col-lg-6 signature-col align-items-start">
      <div>Signed:</div>
      <div class="signed">
        <div :class="['signature-line', signature.user.signature_style_class]">
          <span>{{ signature.signature }}</span>
        </div>
        <div>{{ signature.user.name }}, {{ signatorTitle }}</div>
      </div>
    </div>

    <div class="col-12 col-lg-6 signature-col align-items-start">
      <div>Date:</div>
      <div class="signed">
        <div class="signature-line">{{ signature.created_at }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    signature: {
      type: Object,
    },
    userIsAdmin: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    signatorTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      confirmingDelete: false,
    };
  },
  methods: {
    confirmDelete() {
      this.confirmingDelete = true;
    },
    deleteSignature() {
      this.$emit("deleteSignature");
    },
  },
};
</script>

import { defineStore } from "pinia";
import { ref } from "vue";

export const usePaginationStore = defineStore("pagination", () => {
  const current_page = ref(1);
  const page_size = ref(15);
  const total_pages = ref(null);
  const total_record_count = ref(null);

  return { current_page, page_size, total_pages, total_record_count };
});

export default usePaginationStore;

/* eslint-disable no-param-reassign */
import {
  h, ref, onBeforeMount, watch, provide,
} from "vue";
import Grid from "./layout/parts/grid.vue";
import Header from "./layout/parts/header.vue";
import Help from "./layout/parts/help.vue";
import Container from "./layout/container.vue";
import { Loading } from "../shared/loading";
import {
  widgetLayout,
  setLayout,
  updateWidgetOptions,
  availableWidgets,
  layoutToWidgets,
} from "./store";

export default {
  setup() {
    const error = ref(null);
    const loading = ref(true);
    const editing = ref(false);

    provide("editing", editing);

    const fetchLayout = async () => {
      loading.value = true;
      try {
        const response = await SFCTA.HTTP.get("/api/v1/dashboard");
        const { data } = response;
        layoutToWidgets(data.layout, data.available_widgets);
        availableWidgets.value = data.available_widgets;
        if (!this.widgetLayout) setLayout(data.layout);
        loading.value = false;
      } catch (err) {
        if (err instanceof TypeError || err instanceof Error) {
          error.value = err.message;
          return;
        }
        error.value = JSON.stringify(err);
      }
    };

    const saveLayout = async layout => {
      try {
        await SFCTA.HTTP.post("/api/v1/dashboard", {
          dashboard_layout: layout,
        });
      } catch (err) {
        if (err instanceof TypeError || err instanceof Error) {
          error.value = err.message;
          return;
        }
        error.value = JSON.stringify(err);
      }
    };

    onBeforeMount(() => fetchLayout());

    watch(
      widgetLayout,
      async (layout, prevLayout) => {
        if (prevLayout) {
          await saveLayout(layout);
          updateWidgetOptions(layout);
        }
      },
      { deep: true },
    );

    return () => h(Container, null, {
      header: () => h(Header),
      help: () => h(Help),
      grid: () => {
        if (error.value) {
          return h("div", error.value);
        }
        if (!loading.value) {
          return h(Grid);
        }
        return h(Loading);
      },
    });
  },
};

/**
* @class ProgressReportsNewEdit
* Page-specific script for progress_reports #new and #edit
*/

export default class ProgressReportsShowNewEdit {

  static get UNSAVED_MESSAGE() { return "You have unsaved changes. Are you sure you want to leave?" }

  initialize() {
    this.calculatePercentExpended();
  }

  bindListeners() {
    SFCTA.DOM.listen("#report_type_select", "change", this.handleTypeSelect);
    SFCTA.DOM.listen("#progress_report_budget", "change", this.calculatePercentExpended);
  }

  // Event Listeners

  handleTypeSelect = event => {
    const frequency = event.target.value;
    document.location.href = `${window.location.pathname}?report_type=${event.target.value}${frequency ? "&frequency=" + frequency : ""}`;
  }

  calculatePercentExpended = () => {
    const budgetElement = document.querySelector("#progress_report_budget")
    const budget = budgetElement && budgetElement.value;
    const expendituresElement = document.querySelector("#progress_report_expenditures_to_date")
    const expenditures = (expendituresElement && expendituresElement.value) || 0;

    if (budget && budget > 0) {
      const percent_expended = (expenditures/budget) * 100;
      const expendedElement = document.querySelector("#percent_expended")

      if(expendedElement) {
        expendedElement.innerHTML = percent_expended.toFixed(2);
      }
    }
  }

}

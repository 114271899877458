<template functional>
  <img :src="path" :class="uiState.showList ? 'icon-sm' : 'icon-lg'" />
</template>

<script setup>
import { useUIStateStore } from "../../stores";

const uiState = useUIStateStore();

const props = defineProps({ path: String, isModal: Boolean });
</script>

<style scoped>
.icon-sm {
  height: 40px;
  margin-left: -10px;
}

.icon-lg {
  height: 60px;
  margin-left: -15px;
}

@container gallery (max-width: 250px) {
  .icon-sm {
    height: 60px;
    margin-left: -15px;
  }
}
</style>

<template>
  <span class="form-label mb-1 label-lg w-100">Files</span>
  <div class="list-wrapper mb-3 w-100">
    <ol class="files-list">
      <li v-for="(attachment, i) in attachments" class="files-list-item">
        <div class="row">
          <span class="image-wrapper">
            <Photo
              v-if="isImage(attachment)"
              class="image"
              :url="attachment.url"
            />
            <div v-else class="icon">
              <Document :path="getIconPath(attachment)" />
            </div>
          </span>
          <div>
            <span class="filename">{{ attachment.file.name }}</span>
            <Input
              type="text"
              placeholder="Enter Description"
              label="Description"
              hideLabel
              aria-label="description"
              :value="attachment.description"
              @change="(input) => setDescription({ input, i })"
            />
          </div>
          <button
            className="btn btn-outline-primary delete-file"
            @click.prevent="removeFromList(i)"
          >
            <DeleteIcon />
          </button>
        </div>
      </li>
    </ol>
  </div>
</template>

<script setup>
import Photo from "../../attachment/types/image.vue";
import Document from "../../attachment/types/document.vue";
import { Input } from "../../../shared/form";
import { acceptedTypes } from "./fileTypes";
import Icons from "../../../shared/icons";

const DeleteIcon = Icons.Delete;

const props = defineProps({ attachments: Object });

const isImage = (attachment) => {
  const parts = attachment.file.name.split(".");
  const ext = parts[parts.length - 1];

  const imgExts = ["jpg", "jpeg", "png", "tif", "gif"];

  return imgExts.includes(ext.toLowerCase());
};

const getIconPath = (attachment) => {
  const parts = attachment.file.name.split(".");
  const ext = parts[parts.length - 1];

  return acceptedTypes.includes(ext)
    ? `/images/file_icons/icon_${ext.toUpperCase()}_big.png`
    : `/images/file_icons/icon_UNK_big.png`;
};

const setDescription = ({ input, i }) => {
  props.attachments[i].description = input.value;
};

const removeFromList = (i) => {
  props.attachments.splice(i, 1);
};
</script>

<style scoped>
.form-label {
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
}

.label-lg {
  font-size: 0.95rem;
}

.list-wrapper {
  height: 350px;
  max-height: 60%;
  overflow: hidden;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  margin-block-start: -1rem;
}

.files-list {
  height: 100%;
  margin: 0;
  padding-inline: 0;
  list-style-type: none;
  overflow-y: auto;
}

.files-list-item {
  width: 100%;
  padding-block: 0.5rem;
  border-bottom: 1px solid #ced4da;
  padding-inline: 1rem;
}

.row {
  display: grid;
  grid-template-columns: 80px auto 40px;
  align-items: center;
  gap: 1rem;
}

.filename {
  font-weight: 600;
  margin-block-end: 0.5rem;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.image {
  height: 64px;
  width: 64px;
  padding: 2px;
  object-fit: contain;
  object-position: 50% 50%;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  background-color: #ffffff;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  height: 64px;
  width: 64px;
  padding: 2px;
  background-color: #ffffff;

  .icon-lg {
    height: 56px;
  }
}

.delete-file {
  width: 36px;
  border: none;
  padding: 8px;
  margin-left: -8px;
  margin-top: 6px;
  color: black;

  &:hover {
    background-color: #ffffff;
    border: none;
    color: #d73b35;
  }
}
</style>
;

/**
* @class Nav
*
* Provides a series of methods and events to assist interaction with the navbar
*/
export default class Nav { // eslint-disable-line no-unused-vars

  initialize() {
    this.tabKey = `${window.location.pathname}#tab`;

    const activeTab = sessionStorage.getItem(this.tabKey);
    if(activeTab) {
      const tabToggle = document.querySelector(`a[href='${activeTab}'][data-bs-toggle='tab']`)
      if(tabToggle) {
        new bootstrap.Tab(tabToggle).show();
      }
    }
  }

  bindListeners() {
    document.querySelectorAll("[data-sidebar-toggle]").forEach((node) => {
      if(document.querySelector("#sidebar")) {
        node.addEventListener("click", this.toggleMobileNav);
      } else {
        node.remove();
      }
    });

    const searchForms = document.querySelectorAll("[data-search-form]");
    searchForms.forEach((node) => {
      node.addEventListener("submit", this.handleSearchToggleOrSubmit);
    });

    document.querySelectorAll("[data-nav-select]").forEach((select) => {
      select.addEventListener("change", this.handleNavSelectChange);
    });

    document.querySelectorAll("a[data-bs-toggle='tab']").forEach((node) => {
      node.addEventListener("click", this.storeTab);
    });
  }

  toggleMobileNav = event => {
    const sidebar = document.querySelector("#sidebar");
    if(sidebar.classList.contains("m-show")) {
      sidebar.classList.remove("m-show");
      document.querySelector("[data-sidebar-toggle='show']").classList.remove("d-none");
      document.querySelector("[data-sidebar-toggle='hide']").classList.add("d-none");
    } else {
      sidebar.classList.add("m-show");
      document.querySelector("[data-sidebar-toggle='show']").classList.add("d-none");
      document.querySelector("[data-sidebar-toggle='hide']").classList.remove("d-none");
    }
  }

  handleSearchToggleOrSubmit = event => {
    const form = event.target;
    const inputWrapper = form.querySelector(".collapse");
    const input = inputWrapper.querySelector("input[name='search']");
    const button = form.querySelector("button[type='submit']");

    const shown = inputWrapper.classList.contains("show");
    const doSearch = input.value;

    if(!shown || !doSearch) {
      event.preventDefault();

      if(!shown) {
        setTimeout(() => { input.focus(); }, 0);
        button.classList.remove("btn-outline-light");
        button.classList.add("btn-primary");
      } else {
        button.classList.remove("btn-primary");
        button.classList.add("btn-outline-light");
      }

      new bootstrap.Collapse(inputWrapper).toggle;
    }
  }

  focusSearch = event => {
    const input = event.target.querySelector("input[name='search']");
    input.focus();
  }

  handleNavSelectChange = event => {
    const select = event.target;
    const option = select.selectedOptions[0];

    if(!(option || option.value)) return;

    location.href = option.value;
  }

  storeTab = event => {
    sessionStorage.setItem(`${window.location.pathname}#tab`, event.target.getAttribute("href"));
  }

}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center w-75 h-100 m-auto gap-3 justify-content-center" }
const _hoisted_2 = { class: "fs-5 align-self-start" }
const _hoisted_3 = { class: "d-block mb-3 text-danger align-self-start" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.header), 1 /* TEXT */),
    _createElementVNode("span", _hoisted_3, _toDisplayString($props.error), 1 /* TEXT */),
    _createElementVNode("button", {
      class: _normalizeClass($setup.buttonClasses),
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('retry')))
    }, "Retry", 2 /* CLASS */)
  ]))
}
// TODO: Refactor this, possibly into a Vue app

export default class EditLocks {

  MODAL_SELECTORS = {
    editLockModal: "#edit-lock-modal",
    expirationModal: "#edit-lock-expiration-modal",
    adminConfirmModal: "#edit-lock-admin-confirm-modal",
    statusChangeModal: "#edit-lock-status-change-modal"
  };

  initialize() {
    if(!document.querySelector("meta[name='edit-lock']")) return;

    this.pageTitle = document.title;
    this.initEditLock();
  }

  initEditLock() {
    this.bindModals();
    this.disableExpirationWarning = false;
    if(document.querySelector("#edit-lock-data")) {
      this.startCountdown();
      this.startCheckin();
    }
    this.initialized = true;
  }

  bindListeners() {
    if(!this.initialized) return;

    SFCTA.DOM.listen("[data-edit-lock-method]", "click", this.handleMethodClick);
    SFCTA.DOM.listen("[data-edit-lock-read-only]", "click", this.handleReadOnlyClick);
    SFCTA.DOM.listen("[data-edit-lock-admin-method]", "click", this.handleAdminMethodClick);
    SFCTA.DOM.listen(".edit-lockable-form", "submit", this.handleSubmit);

    window.onfocus = () => {
      clearInterval(this.titleFlashInterval);
    }

    window.onunload = this.handlePageUnload;
  }

  bindModals() {
    Object.entries(this.MODAL_SELECTORS).forEach(([key, selector]) => {
      const element = document.querySelector(selector);
      if(element) {
        this[key] = new bootstrap.Modal(element);
      }
    });
  }

  handleMethodClick = event => {
    const admin = event.target.dataset["editLockAdmin"];
    const action = event.target.dataset["editLockMethod"];
    const id = event.target.dataset["editLockId"] || this.data.id;
    if(!action) return;
    const confirmation = event.target.dataset["confirm"];
    if(!confirmation || window.confirm(confirmation)) {
      this.editLockModal.hide();
      admin ? this.submitAdminAction(action, id) : this.submit(action, id);
    }
  }

  handleReadOnlyClick = event => {
    const readOnly = event.target.dataset["editLockReadOnly"];
    if(readOnly == "true") {
      location.search = "read_only=true";
    } else {
      location.search = location.search.replace(/read_only=\w+/, "");
    }
  }

  handleAdminMethodClick = event => {
    this.makeAdminModal(event.target.dataset["editLockAdminMethod"]);
  }

  handleSubmit = event => {
    this.isFormSubmitted = true;
  }

  handlePageUnload = () => {
    if(this.isFormSubmitted) return;

    const authToken = new FormData();
    var param = document.querySelector("meta[name=csrf-param]").getAttribute("content");
    var token = document.querySelector("meta[name=csrf-token]").getAttribute("content");
    authToken.append(param, token);
    navigator.sendBeacon(`/edit_locks/${this.data.id}/leaving`, authToken);
  }


  ///////////////////////////

  get data() {
    return document.querySelector("#edit-lock-data").dataset;
  }

  adminModalContent(method) {
    switch(method) {
      case "post":
        return "This will force the current user out!";
      case "put":
        return "This will prevent anyone else from editing this resource until you or another admin explicitly release this lock.";
      case "delete":
        return "This will force the current user out!";
    }
  }

  startCountdown() {
    if(!this.data || this.data.state != "active") return;

    const duration = parseInt(this.data.duration);
    if(duration == -1) return this.handlePermanentLock();
    if(!duration) return this.handleDurationError();
    const parts = this.getDurationParts(duration);
    this.countdownInterval = setInterval(this.onCountdownInterval.bind(this, parts), 1000);
  }

  onCountdownInterval(timeParts) {
    this.updateCountdown(timeParts);
    if(this.decrementDuration(timeParts, timeParts.length - 1) < 0) {
      clearInterval(this.countdownInterval);
    }
    if(timeParts[2] == 0) this.expirationWarning(timeParts[1]);
  }

  startCheckin() {
    this.checkinInterval = setInterval(this.checkStatus.bind(this), 10000);
  }

  handlePermanentLock() {
    document.querySelector(".edit-lock-countdown").innerHTML("This lock is permanent");
    document.querySelector(".edit-lock-countdown-full").innerHTML("literally infinite time");
  }

  handleDurationError() {
    document.querySelector(".edit-lock-countdown").innerHTML("Time remaining unknown");
    document.querySelector(".edit-lock-countdown-full").innerHTML("an unknown amount of time");
  }

  getDurationParts(duration) {
    const parts = [0,0,0];

    while(duration > 0) {
      if(duration > 3600) {
        parts[0]++;
        duration -= 3600;
      } else if(duration > 60) {
        parts[1]++;
        duration -= 60;
      } else {
        parts[2] = duration;
        break;
      }
    }

    return parts;
  }

  updateCountdown(parts) {
    document.querySelectorAll(".edit-lock-countdown").forEach((node, index) => {
      const dur = this.prettyDuration(parts);

      if(dur && (node.innerHTML != dur)) {
        node.innerHTML = dur;
      }
    });

    document.querySelectorAll(".edit-lock-countdown-full").forEach((node, index) => {
      let durString = "";

      for(let i = 0; i < 3; i++) {
        durString += `${("0" + parts[i]).slice(-2)}:`;
      }

      node.innerHTML = durString.slice(0, -1);
    });
  }

  prettyDuration(parts, forceUpdate = false) {
    if(parts[0] && (forceUpdate || parts[1] == 59)) return `${parts[0]} hour${parts[0] > 1 ? "s" : ""} remaining`;
    if(parts[1] && (forceUpdate || parts[2] == 59)) return `${parts[1]} minute${parts[1] > 1 ? "s" : ""} remaining`;
    if(parts[0] == 0 && parts[1] == 0) return `${parts[2]} second${parts[2] > 1 ? "s" : ""} remaining`;
  }

  decrementDuration(parts, pos) {
    if(pos < 0) return pos;

    if(parts[pos] > 0) {
      return --parts[pos];
    } else {
      parts[pos] = 59;
      this.decrementDuration(parts, --pos);
    }
  }

  expirationWarning(minutesRemaining) {
    if(!minutesRemaining) return;

    const flashPoints = [1, 3, 5, 10, 20, 60];
    if(!flashPoints.includes(minutesRemaining)) return;

    const flashes = flashPoints.reduce((count, val) => { return count += minutesRemaining < val ? 1 : 0 }, 0);
    if(flashes > 0) this.flashBanner(flashes * 2);
    if(minutesRemaining == 1 && !this.disableExpirationWarning) {
      this.expirationModal.show();
    }
  }

  flashBanner(flashes) {
    const banner = document.querySelector(".edit-lock-flash").children[0];
    if(!banner) return;

    for(let i = 0; i < flashes; i++) {
      setTimeout(function() {
        banner.classList.toggle("attention");
      }, i * 1000);
    }

    setTimeout(function() {
      banner.classList.toggle("attention");
    }, flashes * 1000);
  }

  makeAdminModal(method) {
    document.querySelectorAll("#edit-lock-admin-confirm-modal-content .message-content").forEach((node, index) => {
      node.innerHTML = this.adminModalContent(method);
    });

    document.querySelectorAll("#edit-lock-admin-confirm-modal [data-edit-lock-method]").forEach((node, index) => {
      node.setAttribute("data-edit-lock-method", method);
    });

    this.adminConfirmModal.show();
  }

  submitAdminAction(action, id = this.data.id, params = {}) {
    this.submit(action, id, params, true);
  }

  async submit(action, id = this.data.id, params = {}, admin = false) {
    this.isActionInProgress = true;

    try {
      const url = `${admin ? "/admin" : ""}/edit_locks/${id}`;

      const response = await SFCTA.HTTP.submitRequest(action, url, params);

      if(response.reload) {
        response.reload_with ? (location.search = response.reload_with) : location.reload();
      } else if(response.page_content) {
        this.updatePage(action, response);
        this.flashBanner(1);
      }

      if(response.status_change) {
        this.showStatusChangeModal(response.new_status);
      }
    } finally {
      this.isActionInProgress = false;
    }
  }

  updatePage(action, response) {
    Object.keys(response.page_content).forEach(key => {
      document.querySelectorAll(`#edit-lock-${key}, .edit-lock-${key}`).forEach((node, index) => {
        node.innerHTML = response.page_content[key];
      });
    });

    setTimeout(() => { this.reinitialize(); }, 2000);
  }

  showModal() {
    this.editLockModal.show();
  }

  showStatusChangeModal(new_status) {
    this.modals.forEach((modal) => modal.hide());
    this.statusChangeModal.show();

    if(this.data.state == "active") {
      this.disableExpirationWarning = true;
      if(!document.hasFocus()) this.flashPageTitle();
    }
  }

  flashPageTitle() {
    this.titleFlashInterval = setInterval(() => {
      document.title = "You can edit this ARF";
      setTimeout(() => {
        document.title = this.pageTitle;
      }, 1000);
    }, 2000)
  }

  checkStatus() {
    if(this.isFormSubmitted || this.isActionInProgress) return;

    const state = this.data.state;
    const params = {current_state: state};

    if(state == "enqueued") {
      params.queue_position = this.data.queuePosition
    }

    this.submit("GET", this.data.id, params);
  }

  reinitialize() {
    clearInterval(this.countdownInterval);
    clearInterval(this.checkinInterval);
    this.initEditLock();
  }

}

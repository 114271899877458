import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-89d6761a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form-overlay bg-white"
}
const _hoisted_2 = { class: "content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.uiState.overlay)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          ($setup.uiState.overlay === 'error')
            ? (_openBlock(), _createBlock($setup["Error"], {
                key: 0,
                error: $setup.uiState.error,
                onRetry: $setup.retry
              }, null, 8 /* PROPS */, ["error"]))
            : _createCommentVNode("v-if", true),
          ($setup.uiState.overlay === 'edit')
            ? (_openBlock(), _createBlock($setup["EditForm"], { key: 1 }))
            : _createCommentVNode("v-if", true),
          ($setup.uiState.overlay === 'move' || $setup.uiState.overlay === 'clone')
            ? (_openBlock(), _createBlock($setup["MoveCloneForm"], _normalizeProps(_mergeProps({ key: 2 }, $setup.moveProps)), null, 16 /* FULL_PROPS */))
            : _createCommentVNode("v-if", true),
          ($setup.uiState.overlay === 'delete')
            ? (_openBlock(), _createBlock($setup["DeleteForm"], {
                key: 3,
                id: $props.id
              }, null, 8 /* PROPS */, ["id"]))
            : _createCommentVNode("v-if", true)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CreateSignature = _resolveComponent("CreateSignature")
  const _component_CreateSignatureRequest = _resolveComponent("CreateSignatureRequest")
  const _component_ExistingSignature = _resolveComponent("ExistingSignature")
  const _component_RequestedSignature = _resolveComponent("RequestedSignature")
  const _component_BlankSignature = _resolveComponent("BlankSignature")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($options.signatureTitle) + ":", 1 /* TEXT */),
    ($data.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onMouseover: _cache[2] || (_cache[2] = (...args) => ($options.onHover && $options.onHover(...args))),
          onMouseleave: _cache[3] || (_cache[3] = (...args) => ($options.onUnhover && $options.onUnhover(...args)))
        }, [
          ($data.signing)
            ? (_openBlock(), _createBlock(_component_CreateSignature, {
                key: 0,
                signature_type: $props.signature_type,
                user: $props.user,
                errors: $data.errors,
                confirmation: $props.confirm_create,
                onCreateSignature: $options.createSignature,
                onCancel: _cache[0] || (_cache[0] = $event => ($data.signing = false)),
                onCloseError: $options.closeError
              }, null, 8 /* PROPS */, ["signature_type", "user", "errors", "confirmation", "onCreateSignature", "onCloseError"]))
            : ($data.requesting)
              ? (_openBlock(), _createBlock(_component_CreateSignatureRequest, {
                  key: 1,
                  selectOptions: $data.signatorOptions,
                  signatureType: $props.signature_type,
                  onCancel: _cache[1] || (_cache[1] = $event => ($data.requesting = false)),
                  onSubmit: $options.createSignatureRequest
                }, null, 8 /* PROPS */, ["selectOptions", "signatureType", "onSubmit"]))
              : ($data.signature.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_ExistingSignature, {
                      signature: $data.signature,
                      userIsAdmin: $options.userIsAdmin,
                      hover: $data.hover,
                      signatorTitle: $options.signatorTitle,
                      onDeleteSignature: $options.deleteSignature
                    }, null, 8 /* PROPS */, ["signature", "userIsAdmin", "hover", "signatorTitle", "onDeleteSignature"])
                  ]))
                : ($data.signatureRequest.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_RequestedSignature, {
                        signatureRequest: $data.signatureRequest,
                        hover: $data.hover,
                        userIsAdmin: $options.userIsAdmin,
                        user: $props.user,
                        signatorTitle: $options.signatorTitle,
                        onDelete: $options.deleteSignatureRequest,
                        onSign: $options.sign
                      }, null, 8 /* PROPS */, ["signatureRequest", "hover", "userIsAdmin", "user", "signatorTitle", "onDelete", "onSign"])
                    ]))
                  : (_openBlock(), _createBlock(_component_BlankSignature, {
                      key: 4,
                      user_can_sign: $props.user_can_sign,
                      title: $options.signatureTitle,
                      hover: $data.hover,
                      onSign: $options.sign,
                      onRequest: $options.request
                    }, null, 8 /* PROPS */, ["user_can_sign", "title", "hover", "onSign", "onRequest"]))
        ], 32 /* HYDRATE_EVENTS */))
  ]))
}
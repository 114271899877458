<template>
  <div :class="classNames">
    <h3>{{ props.header }}</h3>
    <button
      v-if="settings && settings.length"
      type="button"
      :class="buttonClasses"
      @click="$emit('showSettings')"
    >
      <SettingsIcon />
    </button>
  </div>
</template>

<script setup>
import { computed } from "vue";
import Icons from "../../../shared/icons";

const SettingsIcon = Icons.Settings;

const props = defineProps({
  isMobile: Boolean,
  header: String,
  isError: Boolean,
  settings: Array,
});

defineEmits(["showSettings"]);

const classNames = computed(() => ({
  "mobile-title": props.isMobile,
  title: !props.isMobile,
  "text-primary": true,
  "text-error": props.isError,
  "text-center": true,
  relative: true,
}));

const buttonClasses = computed(() => ({
  btn: true,
  "btn-outline-dark": true,
  "ms-auto": true,
  rounded: true,
  "btn-mobile": props.isMobile,
}));
</script>

<style scoped>
.btn {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
}

.btn-mobile {
  height: 30px;
  width: 30px;
  padding: 6px;
}

.title {
  padding: 20px;
  padding-block-start: 20px;
  padding-block-end: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  > h3 {
    margin-bottom: 0;
    font-weight: 400;
    padding-inline: 60px;
  }
}

.mobile-title {
  padding-block-start: 20px;
  padding-block-end: 20px;
  margin-inline-start: 20px;
  margin-inline-end: 20px;
  background-color: #f8f9fa;
  color: black;
  display: flex;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold;

  > h3 {
    margin-bottom: 0;
    font-weight: 500;
    padding-inline: 55px;
  }
}
</style>

import DOM from "../utilities/dom";
import FuzzySearch from "fuzzy-search";

export default class AutocompleteHelper {

  constructor() {
    this.autocompletes = [];
  }

  bindListeners() {
    // Global listener to create Autocompletes on demand
    DOM.listen("[data-autocomplete], [data-autocomplete-setup]", "click", this.handleAutocompleteClick, true);
    DOM.listen("[data-autocomplete-item]", "click", this.handleAutocompleteItemClick, true);
  }

  handleAutocompleteClick = event => {
    const target = { event };
    if(!target.dataset["autocomplete"] || target.dataset["autocompleteSetup"]) return;

    this.setupAutocomplete(event.target);
  }

  handleAutocompleteItemClick = event => {
    const target = { event };
    if(!target.dataset["autocompleteItem"]) return;

    const input = target.parentNode.previousElementSibling;

    this.applyValue(input, target.innerHTML);
  }

  applyValue(target, value) {
    switch(target.nodeName.toLowerCase()) {
      case "input":
      case "textarea":
        target.value = value;
        break;
      default:
        target.innerHTML = value;
    }
  }

  setupAutocomplete(node) {
    new AutocompleteElement(node, true).setup();
  }

}

export class AutocompleteElement { // eslint-disable-line no-unused-vars

  constructor(input, autoToggle = false) {
    this.input = input;
    this.fuzzySearch = new FuzzySearch(this.options, null, { order: true });
    this.autoToggle = autoToggle;
  }

  setup() {
    this.currentValue = this.input.value;

    this.createDropdown();

    this.input.setAttribute("data-autocomplete-setup", true)
    this.input.setAttribute("data-bs-toggle", "dropdown");
    this.input.parentNode.appendChild(this.dropdown);
    $(this.input).dropdown();
    if(this.autoToggle) $(this.input).dropdown("toggle");

    this.bindListeners();

    this.ready = true;
  }

  // This class only handles input changes
  // Selecting an autocomplete item is handled in a global event listener
  // See AutocompleteHelper
  bindListeners() {
    this.input.addEventListener("input", this.handleInputChange);
  }

  handleInputChange = event => {
    if(this.input.value === this.currentValue) return;
    this.currentValue = this.input.value;

    this.rebuildDropdown();
  }

  get options() {
    return JSON.parse(this.input.dataset["autocompleteOptions"]);
  }

  get suggestions() {
    if(this.input.value) {
      return this.fuzzySearch.search(this.input.value).slice(0, 9);
    }

    return this.options.slice(0, 9);
  }

  createDropdown() {
    this.dropdown = document.createElement("div");
    this.dropdown.classList.add("dropdown-menu", "w-100");
    this.dropdown.style = "overflow: auto;"

    this.rebuildDropdown();

    return this.dropdown;
  }

  createDropdownItems() {
    this.dropdownItems = this.suggestions.map((opt) => {
      const button = document.createElement("button");
      button.type = "button";
      button.classList.add("dropdown-item");
      button.setAttribute("data-autocomplete-item", true);
      button.innerHTML = opt;

      return button;
    });

    return this.dropdownItems;
  }

  rebuildDropdown() {
    while(this.dropdown.firstChild) {
      this.dropdown.removeChild(this.dropdown.firstChild);
    }

    this.createDropdownItems();

    this.dropdownItems.forEach((opt) => {
      this.dropdown.appendChild(opt);
    });

    if(this.ready) $(this.input).dropdown("update");
  }

}

<template>
  <div v-if="fileStore.files && fileStore.files.length === 0" class="pt-2">
    {{
      pagination.total_record_count === 0
        ? "No files found for this record. Use '+' above to add files."
        : "No files were found matching your search criteria. Try a different search or adjust filters."
    }}
  </div>
  <Detail v-if="uiState.showDetail" :loading="uiState.listLoading" />
  <ul v-else :class="['gap-3 ps-0 w-100', uiState.showList ? 'list' : 'grid']">
    <Item v-for="(file, i) in fileStore.files">
      <template #default="{ className }">
        <button
          v-if="uiState.global"
          :class="[className, 'button-unstyled']"
          @click="toggleDetail(i)"
        >
          <Attachment :file="file" />
        </button>
        <Disclosure v-else :id="uiState.modalId" :className="className" @click="setModalState(i)">
          <Attachment :file="file" />
        </Disclosure>
      </template>
    </Item>
  </ul>
</template>
;

<script setup>
import Item from "./item.vue";
import Attachment from "../attachment";
import { Disclosure } from "../../shared/modal";
import { Detail } from "../attachment/detail";
import { useFileStore, useUIStateStore, usePaginationStore } from "../stores";

const fileStore = useFileStore();
const uiState = useUIStateStore();
const pagination = usePaginationStore();

const setModalState = i => {
  uiState.$patch({ index: i, overlay: false, create: false });
};

const toggleDetail = i => {
  setModalState(i);
  uiState.$patch({ showDetail: !uiState.showDetail });
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.list {
  > * + * {
    margin-top: 1rem;
  }
}

@container gallery (max-width: 250px) {
  .list,
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-top: 0;
  }
}
</style>

<template>
  <li :class="itemClasses">
    <slot :className="innerClasses" />
  </li>
</template>
;

<script setup>
import { inject, computed } from "vue";
import { useUIStateStore } from "../stores";

const uiState = useUIStateStore();

const itemClasses = computed(() => ({
  "item-outer": true,
  border: true,
  "rounded-2": true,
  "mw-100": uiState.showList,
}));

const innerClasses = computed(() => ({
  "item-inner": true,
  "item-inner-list": uiState.showList,
  "item-inner-grid": !uiState.showList,
  "d-flex": true,
  "align-items-center": true,
  "w-100": true,
  "text-start": true,
}));
</script>

<style>
.item-outer {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  &:hover {
    background-color: #f0fdfa;
  }
}

.item-inner-grid {
  flex-direction: column;
  justify-content: center;
  padding-inline: 1rem;
  height: 150px;
}

.item-inner-list {
  justify-content: space-between;
  flex-direction: row-reverse;
}

@container gallery (max-width: 752px) {
  .item-outer {
    padding-bottom: 0;
  }
}

@container gallery (max-width: 250px) {
  .item-inner {
    flex-direction: column;
    justify-content: center;
    padding-inline: 1rem;
    height: 150px;
  }
}
</style>

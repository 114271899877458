import { h } from "vue";
import { useFilterStore } from "../../../stores";
import { Select } from "../../../../shared/form";

const options = [
  { value: "all", label: "All" },
  { value: "img", label: "Images" },
  { value: "doc", label: "Documents" },
  { value: "sheet", label: "Spreadsheets" },
  { value: "pdf", label: "PDFs" },
];

export default {
  setup() {
    const filters = useFilterStore();

    const setFilter = val => filters.$patch({ file_type: val });

    return () => h(Select, {
      options,
      label: "Show only",
      ariaLabel: "File type filter",
      onChange: setFilter,
      value: filters.file_type,
    });
  },
};

/**
* @class AllocationRequestsNewEdit
* Page-specific script for people #edit
*/

export default class AllocationRequestsNewEdit {
  initialize() {
    this.checkMultiphaseJustificationVisibility();
  }

  bindListeners() {
    SFCTA.DOM.listen("#allocation_request_phases", "change", this.handlePhaseChange);
  }

  // Event Listeners
  handlePhaseChange = () => {
    this.checkMultiphaseJustificationVisibility();
  }

  // Scope tab
  checkMultiphaseJustificationVisibility() {
    const phases = document.querySelector("#allocation_request_phases").selectedOptions;

    if(phases && phases.length > 1) {
      SFCTA.Visibility.show("[data-section='multi-phase-justification']");
    } else {
      SFCTA.Visibility.hide("[data-section='multi-phase-justification']");
    }
  }
}

<template>
  <div
    :class="['flex gap-2 justify-content-center align-self-start', !uiState.global && 'hide-xs']"
  >
    <button
      type="button"
      @click="setView(false, false)"
      class="btn btn-outline-primary"
    >
      <GridIcon />
    </button>
    <button
      type="button"
      @click="setView(true, false)"
      class="btn btn-outline-primary hide-xs"
    >
      <ListIcon />
    </button>
    <button
      type="button"
      v-if="uiState.global"
      @click="setView(uiState.showList, true)"
      class="btn btn-outline-primary pad-sm hide-xs"
    >
      <GalleryIcon />
    </button>
  </div>
</template>
;

<script setup>
import Icons from "../../shared/icons";
import { useUIStateStore } from "../stores";

const GridIcon = Icons.Grid;
const ListIcon = Icons.List;
const GalleryIcon = Icons.Images;

const uiState = useUIStateStore();

const setView = (list, detail) => {
  uiState.$patch({ showList: list, showDetail: detail });
};
</script>

<style scoped>
@container gallery (max-width: 250px) {
  .hide-xs {
    display: none;
  }
}

.pad-sm {
  padding-inline: 0.65rem;
}
</style>

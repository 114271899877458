<template>
  <section :class="['widget-container', 'shadow', editing ? '' : 'widget-container--hide-resize']">
    <div v-if="editing" class="dashboard-widget__controls">
      <div v-if="canDrag" class="dashboard-widget__controls--drag-handle">
        <MoveIcon />
      </div>
      <button
        class="dashboard-widget__controls--delete"
        :value="widget.id"
        @click="removeWidget(widget.id)"
      >
        <DeleteIcon />
      </button>
    </div>
    <Error v-if="!!error" :editing="editing" :widget="widget" :error="error" />
    <Table
      v-else-if="isTable"
      :size="size"
      :page="page"
      :id="tableId"
      :records="visibleRecords"
      :fields="meta.columns"
      :perPage="perPage"
      :breakpoint="breakpoint"
      :widget="widget"
      :editing="editing"
      :loading="loading"
      :settings="meta.available_options"
      @sort="onSort"
      @showSettings="onToggleSettings"
    />
    <Html v-else :id="tableId" :breakpoint="breakpoint" :widget="widget" :editing="editing" />
    <Pagination
      v-if="totalPages > 0 && !error"
      :page="page"
      :totalPages="totalPages"
      :perPage="perPage"
      @pageChange="onPageChange"
    />
    <div v-if="!editing" class="dashboard-widget-mobile__border" />
    <div v-else class="dashboard-widget-mobile__dashed" />
    <Overlay
      :visible="showSettings"
      :widget="widget"
      :settings="meta && meta.available_options"
      @cancel="onToggleSettings"
    />
  </section>
</template>

<style>
@import "../../shared/mixins.css";

.widget-container {
  height: 100%;

  &.shadow {
    @mixin respond 718px, box-shadow, none !important;
  }

  & + .vue-resizable-handle {
    right: -5px !important;
    bottom: -5px !important;
    width: 12px !important;
    height: 12px !important;
    background: none !important;
    background-color: #363a40 !important;
    border: 2px solid #363a40;
    border-radius: 2px;

    @mixin respond 718px, right, -25px !important;
  }

  &--hide-resize {
    & + .vue-resizable-handle {
      display: none !important;
    }
  }
}
</style>

<script setup>
import { ref, reactive, watch, computed } from "vue";
import Pagination from "../../shared/pagination";
import MoveIcon from "../../shared/icons/move.vue";
import DeleteIcon from "../../shared/icons/delete.vue";

import Table from "./content/table.vue";
import Html from "./content/html.vue";
import Error from "./content/error.vue";
import Overlay from "./parts/overlay.vue";

import { removeWidget } from "../store";

const props = defineProps({
  widget: Object,
  editing: {
    type: Boolean,
    default: false,
  },
  resizing: {
    type: Boolean,
    default: false,
  },
  itemH: Number,
  itemW: Number,
  canDrag: Boolean,
  breakpoint: String,
});

const tableId = `table-${crypto.randomUUID()}`;

const page = ref(1);
const records = ref([]);
const meta = ref({});
const error = ref(null);
const loading = ref(true);
const sort = ref(null);
const sortDirections = ref(null);
const showSettings = ref(false);

const size = computed(() => ({ h: props.itemH, w: props.itemW }));
const perPage = computed(() => {
  const rowH = 132;
  const gap = 40;
  const widgetH = rowH * size.value.h + gap * (size.value.h - 1);
  const available = widgetH - 190;
  const divisor = size.value.w === 1 ? 92 : 70;
  return Math.floor(available / divisor);
});
const totalPages = computed(() =>
  meta.value.pagination ? Math.ceil(meta.value.pagination.total_record_count / perPage.value) : 1,
);
const isTable = computed(() => !!meta.value.columns);
const options = computed(() => props.widget?.options);
const visibleRecords = computed(() => {
  if (records.value.length) {
    const start = (page.value - 1) * perPage.value;
    const end = start + perPage.value;
    const visible = records.value.filter(r => r.attributes?.project_number).slice(start, end);
    return visible.map(r => ({ ...r.attributes, id: r.id }));
  }
});

const fetchRecords = async (page = 1, reset = false) => {
  if (reset) loading.value = true;

  const newSort = sortDirections.value?.[sort.value] === "asc" ? "desc" : "asc";
  const orderParams = sort.value
    ? {
        order_col: sort.value,
        order_dir: newSort,
      }
    : {};
  const params = {
    page,
    per_page: 25,
    name: props.widget.name,
    ...orderParams,
  };

  try {
    const response = await SFCTA.HTTP.get("/api/v1/dashboard/data", params);
    const { data, meta: responseMeta } = response;
    records.value = reset ? data : [...records.value, ...data];
    meta.value = responseMeta;

    if (reset) {
      page.value = 1;
    }

    if (!sortDirections.value) {
      sortDirections.value = responseMeta.columns
        .map(col => col.sort)
        .filter(Boolean)
        .reduce((keys, col) => ({ ...keys, [col]: "asc" }), {});
    } else {
      sortDirections.value[sort.value] = newSort;
    }

    loading.value = false;
  } catch (err) {
    if (err instanceof TypeError || err instanceof Error) {
      error.value = err.message;
      return;
    }
    error.value = JSON.stringify(err);
  }
};

const onPageChange = newPage => {
  if (newPage < 1 || newPage > totalPages.value) return;
  page.value = newPage;
  if (newPage * perPage.value > records.value.length) {
    return fetchRecords(meta.value.pagination.current_page + 1);
  }
};

const onSort = field => {
  sort.value = field;
  fetchRecords(1, true);
};

const onToggleSettings = () => {
  showSettings.value = !showSettings.value;
};

watch([perPage, meta], ([newPerPage, newMeta]) => {
  const totalCount = newMeta.pagination?.total_record_count;

  if (newPerPage >= totalCount) page.value = 1;
  if (totalPages.value < page.value) page.value = totalPages.value;

  if (page.value * newPerPage > records.value.length && records.value.length < totalCount) {
    return fetchRecords(meta.value.pagination?.current_page + 1);
  }
});

watch(options, () => fetchRecords(1, true), { deep: true, immediate: true });
</script>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.layoutsByBreakpoint)
    ? (_openBlock(), _createBlock($setup["GridLayout"], {
        key: 0,
        layout: $setup.layoutsByBreakpoint[$setup.breakpoint],
        "onUpdate:layout": _cache[0] || (_cache[0] = $event => (($setup.layoutsByBreakpoint[$setup.breakpoint]) = $event)),
        numCols: 3,
        cols: { lg: 3, md: 1, sm: 1 },
        breakpoints: { lg: 1274, md: 710, sm: 600 },
        "row-height": 132,
        "is-draggable": $setup.editing,
        "is-resizable": true,
        responsive: true,
        "responsive-layouts": $setup.layoutsByBreakpoint,
        "vertical-compact": true,
        "use-css-transforms": true,
        margin: [40, 40],
        autoSize: true,
        class: "dashboard-grid",
        onBreakpointChanged: $setup.onBreakpointChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.layoutsByBreakpoint[$setup.breakpoint], (item) => {
            return (_openBlock(), _createBlock($setup["GridItem"], {
              key: item.i,
              x: item.x,
              y: item.y,
              w: item.w,
              h: item.h,
              i: item.i,
              maxW: $setup.editing ? 3 : item.w,
              maxH: $setup.editing ? 18 : item.h,
              minH: 3,
              "drag-allow-from": ".dashboard-widget__controls--drag-handle",
              onResized: $setup.onUpdate,
              onMoved: $setup.onUpdate
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Widget"], {
                  widget: $setup.getWidget(item.i),
                  editing: $setup.editing,
                  resizing: item.resizing,
                  itemH: item.h,
                  itemW: item.w,
                  canDrag: $setup.canDrag,
                  breakpoint: $setup.breakpoint
                }, null, 8 /* PROPS */, ["widget", "editing", "resizing", "itemH", "itemW", "canDrag", "breakpoint"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["x", "y", "w", "h", "i", "maxW", "maxH"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["layout", "is-draggable", "responsive-layouts"]))
    : _createCommentVNode("v-if", true)
}
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(['offcanvas offcanvas-start', $props.className && $props.className]),
      tabindex: "-1",
      id: $props.id,
      "aria-labelledby": $setup.titleRef.id,
      style: {"width":"450px"}
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 10 /* CLASS, PROPS */, _hoisted_1)
  ]))
}
/**
 * @class HTTPHelper
 *
 * Provides methods to simplify XHR requests
 */
export default class HTTP {
  // eslint-disable-line no-unused-vars

  static async submitRequest(method, url, params = {}, options = {}) {
    let query = url;
    const { headers: headerOpts, ...requestOpts } = options;
    const headers = this.headers(method, headerOpts);

    if (method.toLowerCase() === "get" && Object.keys(params).length) {
      query = `${url}?${this.buildQueryString(url, params)}`;
    } else if (["post", "put", "patch"].includes(method.toLowerCase())) {
      if (options.submitAsForm) {
        headers.delete("Content-Type");
        requestOpts.body = new FormData();
        Object.keys(params)
          .filter(key => params[key])
          .forEach(key => requestOpts.body.append(key, params[key]));
      } else {
        requestOpts.body = JSON.stringify(params);
      }
    }

    const response = await fetch(query, {
      method,
      headers,
      ...requestOpts,
    });

    return response.json();
  }

  static get(url, params = {}, options = {}) {
    // "null" and "undefined" generally don't behave in GET requests
    const filteredParams = Object.fromEntries(Object.entries(params).filter((([_, v]) => v != null)))
    return this.submitRequest("GET", url, filteredParams, options);
  }

  static post(url, params = {}, options = {}) {
    return this.submitRequest("POST", url, params, options);
  }

  static put(url, params = {}, options = {}) {
    return this.submitRequest("PUT", url, params, options);
  }

  static patch(url, params = {}, options = {}) {
    return this.submitRequest("PATCH", url, params, options);
  }

  static delete(url, params = {}, options = {}) {
    return this.submitRequest("DELETE", url, params, options);
  }

  static headers(method, headerOpts) {
    if (method.toLowerCase() === "get") return;

    const providedHeaders = { ...this.defaultHeaders, ...headerOpts };
    const headers = new Headers();

    Object.keys(providedHeaders).forEach(key => {
      headers.append(key, providedHeaders[key]);
    });

    return headers;
  }

  static get defaultHeaders() {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").content,
    };
  }

  static buildQueryString(url, params = {}) {
    const existingParams = new URL(url, window.location.origin).searchParams;
    const urlSearchParams = new URLSearchParams(existingParams);

    Object.entries(params).forEach(([key, value]) => {
      urlSearchParams.append(key, value);
    });

    return urlSearchParams.toString();
  }
}

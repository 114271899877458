export default class CashFlowHelper { // eslint-disable-line no-unused-vars

  bindListeners() {
    document.addEventListener("cocoon:after-insert", this.handleCashFlowCocoonInsert);
  }

  get currentFiscalYear() {
    const today = new Date();
    const year = today.getFullYear();
    const isFirstHalf = today.getMonth() < 7;

    return isFirstHalf ? year - 1 : year;
  }

  get cashFlowAssociations() {
    return ["cash_flow", "allocation_request_fund"]
  }

  // Listeners

  handleCashFlowCocoonInsert = event => {
    const [_contentHTML, originalEvent, addedNode] = event.detail;

    if (originalEvent.target.dataset.association === "cash_flow_year") {
      this.setUpCashFlowYear(originalEvent, addedNode);
    } else if(this.cashFlowAssociations.includes(originalEvent.target.dataset.association)) {
      this.setUpCashFlow(originalEvent, addedNode);
    }
  }

  setUpCashFlow(originalEvent, addedNode) {
    const uniqueId = addedNode.dataset["cocoonId"];
    const cashFlowContainer = addedNode.querySelector("[data-cash-flow]");

    const totalEl = cashFlowContainer.querySelectorAll("[data-cash-flow-total]").forEach((el) => {
      el.dataset["cashFlowTotal"] = `${el.dataset["cashFlowTotal"]}-${uniqueId}`;
    });

    cashFlowContainer.querySelectorAll("[data-cash-flow-year='amount']").forEach((el) => {
      const match = el.dataset["sum"].match(/\[data-cash-flow-total=['"](.*?)['"]\]/);
      if(!match) return;

      el.dataset["sum"] = el.dataset["sum"].replace(match[1], `${match[1]}-${uniqueId}`);
    });
  }

  setUpCashFlowYear(originalEvent, addedNode) {
    if (addedNode) {
      const trigger = originalEvent.target;
      const cashFlowContainer = trigger.closest("[data-cash-flow]");
      const canEdit = cashFlowContainer.dataset.editable;
      const canEditPast = cashFlowContainer.dataset.pastEditable;

      const direction = trigger.dataset.cashFlowSiblingLookup || "previous";
      const siblingNode = this.siblingCashFlowYearNode(addedNode, direction);
      if (!siblingNode) return;

      const siblingFYNode = siblingNode.querySelector("[data-cash-flow-year='fiscal_year']");
      const siblingFY = parseInt(siblingFYNode.value);

      const labelEl = addedNode.querySelector("label");
      const fyEl = addedNode.querySelector("[data-cash-flow-year='fiscal_year']");
      const amountEl = addedNode.querySelector("[data-cash-flow-year='amount']");

      if (fyEl) {
        const newFY = direction == "next" ? siblingFY - 1 : siblingFY + 1;
        fyEl.value = newFY;

        if (labelEl) {
          labelEl.textContent = this.fiscalYearString(newFY);
        }

        if (amountEl) {
          if (!canEdit || (!canEditPast && parseInt(fyEl.value) < this.currentFiscalYear)) {
            amountEl.disabled = true;
          }
        }

        if (direction == "previous" && newFY < this.currentFiscalYear) {
          trigger.dispatchEvent(new Event("click", { bubbles: true }));
        } else if (direction == "next" && newFY > this.currentFiscalYear) {
          trigger.dispatchEvent(new Event("click", { bubbles: true }));
        }
      }
    }
  }

  siblingCashFlowYearNode(baseNode, direction) {
    const attr = `${direction}ElementSibling`;

    let siblingNode = baseNode[attr];
    while (siblingNode && !siblingNode.hasAttribute("data-cash-flow-year-container")) {
      siblingNode = siblingNode[attr];
    }

    return siblingNode;
  }

  fiscalYearString(year) {
    if (!year) return;

    const nextYear = year + 1;
    const nextYearShort = `${nextYear}`.slice(-2);

    return `FY${year}/${nextYearShort}`
  }

}

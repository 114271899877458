import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6305bf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "aria-label", "value", "placeholder"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_uid = _resolveDirective("uid")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: _normalizeClass([
        'form-label mb-1',
        $props.labelLg && 'label-lg',
        $props.hideLabel && 'visually-hidden',
      ]),
      for: $setup.input.id
    }, _toDisplayString($props.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1),
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      type: $props.type,
      class: "form-control",
      "aria-label": $props.ariaLabel,
      value: $props.value,
      placeholder: $props.placeholder,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('change', $setup.input)))
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [
      [_directive_uid]
    ])
  ]))
}
<template>
  <div>
    <label :class="['form-label mb-1', labelLg && 'label-lg']" :for="select.id">{{ label }}</label>
    <select
      v-uid
      ref="select"
      class="form-select"
      :aria-label="ariaLabel"
      :value="value"
      @change="$emit('change', select.value)"
    >
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div> </template
>;

<script setup>
import { ref } from "vue";

const props = defineProps({
  options: {
    type: Array,
    default: [],
  },
  label: String,
  ariaLabel: String,
  value: [String, Number],
  labelLg: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["change"]);

const select = ref({});
</script>

<style scoped>
.form-label {
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
}
.label-lg {
  font-size: 0.95rem;
}
</style>

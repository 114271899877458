<template>
  <Disclosure
    :id="uiState.modalId"
    className="btn btn-outline-primary add-attachment-button"
    @click="openAddModal"
  >
    <PlusIcon />
  </Disclosure>
</template>
;

<script setup>
import Icons from "../../shared/icons";
import { Disclosure } from "../../shared/modal";
import { useUIStateStore } from "../stores";

const uiState = useUIStateStore();

const PlusIcon = Icons.Plus;

const openAddModal = () => {
  uiState.$patch({ create: true, overlay: false });
};
</script>

<style>
.add-attachment-button {
  width: 43px;
  border: 1px solid #006c69 !important;
}
</style>

/**
* @class DOM
*
* Provides a variety of helper methods to make event handling and DOM checking/manipulation easier
*/
export default class DOM { // eslint-disable-line no-unused-vars

  static onReady(callback) {
    document.addEventListener("DOMContentLoaded", callback);
  }

  static listen(selector, event, callback, global = false) {
    if (global) {
      document.addEventListener(event, (e) => {
        if (e.target.matches(selector)) callback(e);
      });
    } else {
      document.querySelectorAll(selector).forEach((el) => {
        el.addEventListener(event, callback);
      });
    }
  }

  static appendTableRow(table, row) {
    if (typeof row == "string") {
      table.innerHTML = table.innerHTML + row;
    } else {
      table.appendChild(row);
    }
  }

  static isOneOfTags(element, tagNames) {
    return tagNames.some((tagName) => {
      if (this.isTagName(element, tagName)) return true;
    });
  }

  static isTagName(element, matchingTagName) {
    return element.tagName.toLowerCase() == matchingTagName.toLowerCase();
  }

  static isSelect(element) {
    return this.isTagName(element, "select");
  }

}

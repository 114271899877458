import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-afc60f57"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = ["id", "aria-expanded"]
const _hoisted_3 = ["aria-labelledby"]
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showMenu)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          id: $props.id,
          "aria-expanded": $setup.visible ? true : null,
          "data-bs-toggle": "dropdown",
          class: "dropdown-toggle dropdown-toggle-no-caret dashboard-top-bar__add-toggle",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.toggleVisible()))
        }, [
          _createVNode($setup["AddIcon"])
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("ul", {
      "aria-labelledby": $props.id,
      class: "dropdown-menu"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          onClick: $event => ($setup.addWidget(item)),
          class: _normalizeClass(['dropdown-item', item.active ? 'disabled' : ''])
        }, _toDisplayString(item.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_4))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 8 /* PROPS */, _hoisted_3)
  ]))
}
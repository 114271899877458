import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aa1fe3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_uid = _resolveDirective("uid")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: _normalizeClass(['form-label mb-1', $props.labelLg && 'label-lg']),
      for: $setup.textarea.id
    }, _toDisplayString($props.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1),
    _withDirectives((_openBlock(), _createElementBlock("textarea", {
      ref: "textarea",
      class: "form-control",
      "aria-label": $props.ariaLabel,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('change', $setup.textarea.value)))
    }, [
      _createTextVNode(_toDisplayString($props.value), 1 /* TEXT */)
    ], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2)), [
      [_directive_uid]
    ])
  ]))
}
import DOM from "../utilities/dom";

export default class Visibility { // eslint-disable-line no-unused-vars

  initialize() {
    this.setInitialVisibility();
  }

  bindListeners() {
    this.bindInputListeners();
    this.bindControlListeners();
  }

  bindInputListeners() {
    const inputTriggers = Array.from(document.querySelectorAll("input[data-show], select[data-show], input[data-hide], select[data-hide]")).map((node) => {
      return DOM.isTagName(node, "option") ? node.parentNode : node;
    });

    inputTriggers.forEach((node) => {
      node.addEventListener("change", this.handleVisibilityChange);
    });
  }

  bindControlListeners() {
    DOM.listen("[data-show]:not(input, select), [data-hide]:not(input, select)", "click", this.handleVisibilityChange);
  }

  handleVisibilityChange = event => {
    this.setVisibilityFrom(event.target);
  }

  setInitialVisibility() {
    document.querySelectorAll("input[data-show], select[data-show], input[data-hide], select[data-hide]").forEach((node) => {
      if(DOM.isTagName(node, "option")) {
        node = node.parentNode;
      }

      this.setVisibilityFrom(node);
    });
  }

  setVisibilityFrom(element) {
    const trigger = SFCTA.DOM.isSelect(element) ? element.selectedOptions[0] : element;

    if(trigger.dataset.show) {
      const target = trigger.dataset.show;
      this.isActive(trigger) ? this.show(target) : this.hide(target);
    }
    if(trigger.dataset.hide) {
      const target = trigger.dataset.hide;
      this.isActive(trigger) ? this.hide(trigger.dataset.hide) : this.show(trigger.dataset.hide);
    }
    if(trigger.dataset.toggle) {
      this.toggle(trigger.dataset.toggle);
    }

    if(trigger.dataset.visibilityConfirm) {
      document.querySelectorAll(trigger.dataset.visibilityConfirm).forEach((confirmNode) => {
        if(confirmNode.isSameNode(trigger)) return;
        this.setVisibilityFrom(confirmNode);
      });
    }
  }

  isActive(element) {
    switch(element.tagName.toLowerCase()) {
      case "input":
        if(["checkbox", "radio"].includes(element.type)) {
          return !!element.checked;
        } else {
          return !!element.value;
        }
      default:
        return true;
    }
  }

  show(selector) {
    document.querySelectorAll(selector).forEach((node) => {
      if(node.classList.contains("collapse")) {
        $(node).collapse("show");
      } else {
        node.classList.remove("d-none");
      }
    });
  }

  hide(selector) {
    document.querySelectorAll(selector).forEach((node) => {
      if(node.classList.contains("collapse")) {
        $(node).collapse("hide");
      } else {
        node.classList.add("d-none");
      }
    });
  }

  toggle(selector) {
    document.querySelectorAll(selector).forEach((node) => {
      if(node.classList.contains("collapse")) {
        $(node).collapse("toggle");
      } else {
        node.classList.toggle("d-none");
      }
    });
  }

}

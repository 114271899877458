<template>
  <div class="signature row">
    <transition name="fade">
      <div v-if="confirmingDelete" class="signature-overlay">
        <button type="button" class="btn btn-danger" @click="deleteSignatureRequest">
          Confirm Delete
        </button>
        <button type="button" class="btn btn-light" @click="confirmingDelete = false">
          Cancel
        </button>
      </div>

      <div v-else-if="hover && userCanSign" class="signature-overlay">
        <button type="button" class="btn btn-primary" @click="sign">Sign</button>
        <button type="button" class="btn btn-danger" @click="confirmDelete">
          Delete Signature Request
        </button>
      </div>

      <div v-else-if="hover && userCanDelete" class="signature-overlay">
        <button type="button" class="btn btn-danger" @click="confirmDelete">
          Delete Signature Request
        </button>
      </div>
    </transition>

    <div class="col-12 text-center">
      <p class="font-weight-bold">
        <span v-if="userCanSign">Your</span>
        <span v-else>{{ signatureRequest.signator.name }}'s</span>
        Signature Has Been Requested By {{ signatureRequest.user.name }} on
        {{ signatureRequest.created_at }}
      </p>
    </div>

    <div class="col-12 col-lg-6 signature-col align-items-start">
      <div>Signed:</div>
      <div class="signed">
        <div class="signature-line signature-font-0">&nbsp;</div>
        <div>
          {{ signatureRequest.signator.name }}, {{ signatureRequest.signator.title }},
          {{ signatureRequest.signator.sponsor.abbreviation }}
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6 signature-col align-items-start">
      <div>Date:</div>
      <div class="signed">
        <div class="signature-line">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    signatureRequest: {
      type: Object,
    },
    userIsAdmin: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      confirmingDelete: false,
    };
  },

  methods: {
    sign() {
      this.$emit("sign");
    },
    confirmDelete() {
      this.confirmingDelete = true;
    },
    deleteSignatureRequest() {
      this.$emit("delete");
    },
  },

  computed: {
    userCanDelete() {
      return this.userIsAdmin || this.user.id == this.signatureRequest.user.id;
    },
    userCanSign() {
      return this.user.id == this.signatureRequest.signator.id;
    },
  },
};
</script>

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94f37d86"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pt-2"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.fileStore.files && $setup.fileStore.files.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.pagination.total_record_count === 0
        ? "No files found for this record. Use '+' above to add files."
        : "No files were found matching your search criteria. Try a different search or adjust filters."), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.uiState.showDetail)
      ? (_openBlock(), _createBlock($setup["Detail"], {
          key: 1,
          loading: $setup.uiState.listLoading
        }, null, 8 /* PROPS */, ["loading"]))
      : (_openBlock(), _createElementBlock("ul", {
          key: 2,
          class: _normalizeClass(['gap-3 ps-0 w-100', $setup.uiState.showList ? 'list' : 'grid'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fileStore.files, (file, i) => {
            return (_openBlock(), _createBlock($setup["Item"], null, {
              default: _withCtx(({ className }) => [
                ($setup.uiState.global)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: _normalizeClass([className, 'button-unstyled']),
                      onClick: $event => ($setup.toggleDetail(i))
                    }, [
                      _createVNode($setup["Attachment"], { file: file }, null, 8 /* PROPS */, ["file"])
                    ], 10 /* CLASS, PROPS */, _hoisted_2))
                  : (_openBlock(), _createBlock($setup["Disclosure"], {
                      key: 1,
                      id: $setup.uiState.modalId,
                      className: className,
                      onClick: $event => ($setup.setModalState(i))
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["Attachment"], { file: file }, null, 8 /* PROPS */, ["file"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "className", "onClick"]))
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ], 2 /* CLASS */))
  ], 64 /* STABLE_FRAGMENT */))
}
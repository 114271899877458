import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode($setup["Modal"], {
      id: $setup.uiState.modalId,
      class: "attachment-modal",
      title: $setup.title
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "modal-body d-flex align-items-center position-relative",
          style: _normalizeStyle($setup.uiState.create && { height: '550px' })
        }, [
          ($setup.uiState.modalLoading || (!$setup.file && !$setup.uiState.create))
            ? (_openBlock(), _createBlock($setup["Loading"], { key: 0 }))
            : ($setup.uiState.create)
              ? (_openBlock(), _createBlock($setup["BulkCreateForm"], { key: 1 }))
              : (_openBlock(), _createBlock($setup["Body"], {
                  key: 2,
                  file: $setup.file
                }, null, 8 /* PROPS */, ["file"])),
          _createVNode($setup["Overlay"], {
            id: $setup.file ? $setup.file.id : null
          }, null, 8 /* PROPS */, ["id"])
        ], 4 /* STYLE */),
        (!$setup.uiState.modalLoading && $setup.file)
          ? (_openBlock(), _createBlock($setup["Footer"], {
              key: 0,
              file: $setup.file
            }, null, 8 /* PROPS */, ["file"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["id", "title"])
  ]))
}
import { h } from "vue";
import Signature from "./signature.vue";

export default {
  props: {
    data: { type: Array, default: [] },
  },

  setup({ data }) {
    return () => data.map(s => {
      const {
        existing_signature: es, existing_signature_request: esr, user: u, ...rest
      } = s;

      /* eslint-disable camelcase */
      const existing_signature = JSON.parse(es);
      const existing_signature_request = JSON.parse(esr);
      const user = JSON.parse(u);

      const signatureProps = {
        existing_signature,
        existing_signature_request,
        user,
        ...rest,
      };
      return h(Signature, { ...signatureProps });
    });
  },
};

import { h } from "vue";
import BasePagination from "../../shared/pagination";
import { usePaginationStore, useFilterStore } from "../stores";

export default {
  setup() {
    const pagination = usePaginationStore();
    const filters = useFilterStore();

    const onPageChange = page => {
      filters.$patch({ page });
    };

    return () => h(BasePagination, {
      page: pagination.current_page,
      perPage: pagination.page_size,
      totalPages: pagination.total_pages,
      visiblePages: 5,
      positionAbsolute: false,
      onPageChange,
    });
  },
};

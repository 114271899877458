<template>
  <div class="signature row">
    <transition name="fade">
      <div v-if="hover && user_can_sign" class="signature-overlay">
        <button type="button" class="btn btn-primary" @click="sign">Sign</button>
        <button type="button" class="btn btn-secondary" @click="request">
          Request Another User's Signature
        </button>
      </div>
    </transition>

    <div class="col-12 col-lg-6 signature-col">
      <div>Signed:</div>
      <div class="signature-line">&nbsp;</div>
    </div>

    <div class="col-12 col-lg-6 signature-col">
      <div>Date:</div>
      <div class="signature-line">&nbsp;</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user_can_sign: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    sign() {
      this.$emit("sign");
    },
    request() {
      this.$emit("request");
    },
  },
};
</script>

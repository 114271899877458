<template>
  <form class="d-flex gap-3 h-100 justify-content-center align-items-center">
    <button
      type="submit"
      class="btn btn-danger btn-lg"
      @click.prevent="fileStore.deleteAttachment(id)"
    >
      Confirm Delete
    </button>
    <button type="button" class="btn btn-outline-dark btn-lg cancel" @click="closeOverlay">
      Cancel
    </button>
  </form>
</template>

<script setup>
import { useUIStateStore, useFileStore } from "../stores";

defineProps(["id"]);

const uiState = useUIStateStore();
const fileStore = useFileStore();

const closeOverlay = () => (uiState.overlay = false);
</script>

<style scoped>
@import "../../shared/mixins.css";
@mixin cancel-button;
</style>

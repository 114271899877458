import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24b7ca52"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-bs-target"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass($props.className ? $props.className : 'button-unstyled'),
    "data-bs-toggle": "offcanvas",
    "data-bs-target": $setup.selector
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
import { createApp } from "vue";
import { createPinia } from "pinia";
import { Uid } from "../directives";

import Gallery from "./gallery/controller";

export default class GalleryApp {
  initialize() {
    const renderTargets = document.querySelectorAll("[data-attachment-gallery]");

    renderTargets.forEach((el, i) => {
      /* eslint-disable no-param-reassign */
      el.id = `gallery-app_${i}`;
      el.classList.add("attachment-gallery");
      this.loadGalleryApp(el, i);
    });
  }

  loadGalleryApp(el, i) {
    const props = JSON.parse(el?.getAttribute("data"));

    const pinia = createPinia();

    const app = createApp(Gallery, { ...props, appId: i });
    app.use(pinia);
    app.directive("uid", Uid);
    app.config.unwrapInjectedRef = true;
    app.mount(`#${el.id}`);
  }
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e6907a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-footer"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "m-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.uiState.create)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['button-wrapper', !$setup.uiState.global && 'me-auto'])
        }, [
          _createElementVNode("a", {
            download: "",
            href: $setup.downloadUrl,
            class: "btn btn-outline-primary"
          }, " Download ", 8 /* PROPS */, _hoisted_2),
          ($setup.showRecordLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: $setup.attachableUrl,
                target: "_blank",
                class: "btn btn-outline-primary"
              }, " View Record ", 8 /* PROPS */, _hoisted_3))
            : _createCommentVNode("v-if", true),
          ($setup.canUpdate)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                type: "button",
                class: "btn btn-outline-primary",
                onClick: _cache[0] || (_cache[0] = $event => ($setup.showOverlay('edit')))
              }, " Edit "))
            : _createCommentVNode("v-if", true),
          ($setup.canMove && !$setup.uiState.sidebar)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                type: "button",
                class: "btn btn-outline-primary",
                onClick: _cache[1] || (_cache[1] = $event => ($setup.showOverlay('move')))
              }, " Move "))
            : _createCommentVNode("v-if", true),
          ($setup.canClone && !$setup.uiState.sidebar)
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                type: "button",
                class: "btn btn-outline-primary",
                onClick: _cache[2] || (_cache[2] = $event => ($setup.showOverlay('clone')))
              }, " Clone "))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */),
        _createElementVNode("div", _hoisted_4, [
          ($setup.canDelete)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-outline-danger",
                onClick: _cache[3] || (_cache[3] = $event => ($setup.showOverlay('delete')))
              }, " Delete "))
            : _createCommentVNode("v-if", true)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}
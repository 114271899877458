export const acceptedTypes = [
  "doc",
  "docx",
  "gif",
  "jpg",
  "jpeg",
  "key",
  "kml",
  "mpk",
  "pdf",
  "png",
  "ppt",
  "pptx",
  "rtf",
  "tif",
  "txt",
  "xer",
  "xls",
  "xlsm",
  "xlsx",
  "zip",
];

import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0246d34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check form-switch" }
const _hoisted_2 = ["checked"]
const _hoisted_3 = ["for"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_uid = _resolveDirective("uid")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      class: "form-check-input switch",
      type: "checkbox",
      role: "switch",
      checked: $props.checked,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('change', $setup.input.checked)))
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [
      [_directive_uid]
    ]),
    _createElementVNode("label", {
      class: "form-check-label",
      for: $setup.input.id
    }, _toDisplayString($props.label), 9 /* TEXT, PROPS */, _hoisted_3)
  ]))
}
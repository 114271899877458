<template>
  <table v-if="fields" :class="[tableClass, 'table-primary', 'table']">
    <thead class="table-primary">
      <tr>
        <th
          v-for="field in fieldsBySize"
          :key="field.field"
          :class="['widget-th', 'text-primary', thClass(field.field)]"
        >
          <span v-if="!field.sort" class="widget-th-text">
            {{ field.header }}
          </span>
          <a
            v-if="field.sort"
            class="widget-th-text widget-th-sortable"
            @click="$emit('sort', field.sort)"
          >
            {{ field.header }}
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!records">
        <td class="no-records" :colspan="fieldsBySize.length">No Records Found</td>
      </tr>
      <tr v-for="record in records" :key="record.id">
        <Field v-for="field in fieldsBySize" :key="field.field" :record="record" :field="field" />
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed } from "vue";
import Field from "./field.vue";

const props = defineProps({
  id: String,
  size: Object,
  page: {
    type: Number,
    default: 1,
  },
  records: Array,
  fields: Array,
  breakpoint: String,
});

defineEmits(["sort"]);

const layout = computed(() => {
  return props.size.w === 2 || props.breakpoint === "md" ? "md" : props.size.w === 1 ? "sm" : "lg";
});

const fieldsBySize = computed(() => {
  const visible =
    layout.value === "md"
      ? props.fields.filter(col => col.breakpoint !== "lg")
      : layout.value === "sm"
      ? props.fields.filter(col => col.breakpoint === "sm")
      : props.fields;
  return visible;
});

const tableClass = computed(() => {
  return `widget-table count-${fieldsBySize.value.length}`;
});

const thClass = field => {
  return field === "project_description"
    ? "widget-th-description"
    : field === "status"
    ? "widget-th-status"
    : "";
};
</script>

<style scoped>
@import "../../../../shared/mixins.css";

.widget-table {
  margin-bottom: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    background: none;
  }

  @mixin respond 400px, width, 500px;

  & > tbody {
    border-top: 1px solid !important;

    tr {
      position: relative;

      &:hover,
      &:focus-visible {
        > td:not(.no-records) {
          --bs-table-accent-bg: #f0fdfa;
        }
      }
    }
  }
}

.widget-th {
  width: var(--thW, auto);
  background: #e9ecef;
  font-weight: normal;

  &-text {
    display: inline-block;
    height: 21px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-sortable {
    color: currentColor;
    cursor: pointer;
  }

  &-description {
    width: var(--descW, auto);
  }

  &-status {
    width: var(--statusW, --thW);
  }

  &:first-child {
    border-top-left-radius: 4px;

    @mixin respond 718px, border-radius, 0;
  }

  &:last-child {
    border-top-right-radius: 4px;

    @mixin respond 718px, border-radius, 0;
  }
}

.count-4 {
  --thW: 25%;
  --descW: 30%;
  --statusW: 15%;
  --height: 64.8px;
}

.count-5 {
  --thW: 20%;
  --descW: 27%;
  --statusW: 13%;
  --height: 64.8px;
}

.count-6 {
  --thW: 17%;
  --descW: 22%;
  --statusW: 10%;
  --height: 43.2px;
}

.count-7 {
  --thW: 14.5%;
  --descW: 19.5%;
  --statusW: 8%;
  --height: 43.2px;
}

.count-8 {
  --thW: 12%;
  --descW: 17%;
  --statusW: 5%;
  --height: 43.2px;
}

.count-9 {
  --thW: 11%;
  --descW: 18%;
  --statusW: 5%;
  --height: 43.2px;
}

.count-10 {
  --thW: 10%;
  --descW: 15%;
  --statusW: 5%;
  --height: 43.2px;
}

.no-records {
  text-align: center;
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  @mixin respond 718px, border, none;
}
</style>

<template>
  <div v-if="uiState.overlay" class="form-overlay bg-white">
    <div class="content">
      <Error v-if="uiState.overlay === 'error'" :error="uiState.error" @retry="retry" />
      <EditForm v-if="uiState.overlay === 'edit'" />
      <MoveCloneForm
        v-if="uiState.overlay === 'move' || uiState.overlay === 'clone'"
        v-bind="moveProps"
      />
      <DeleteForm v-if="uiState.overlay === 'delete'" :id="id" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Error } from "../../../atomic";
import { DeleteForm, EditForm, MoveCloneForm } from "../../../form";
import { useUIStateStore } from "../../../stores";

defineProps(["id"]);

const uiState = useUIStateStore();

const moveProps = computed(() => {
  const clone = uiState.overlay === "clone";
  return {
    title: clone ? "Clone and attach to..." : "Move to...",
    ariaLabel: clone ? "Clone and attach to..." : "Move to...",
    clone,
  };
});

const retry = () => {
  uiState.$patch({ error: null, overlay: false });
};
</script>

<style scoped>
.form-overlay {
  --bs-bg-opacity: 0.9;

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 800px;
}
</style>

export default class Assignments {

  bindListeners() {
    SFCTA.DOM.listen("[data-assignment]", "change", this.setAssignment);
  }

  setAssignment = event => {
    const attributeName = event.target.dataset.assignment;
    const newValue = event.target.selectedOptions[0].value;

    SFCTA.HTTP.post(`${location.href}/update_attribute`, {
      attr: attributeName,
      value: newValue
    });
  }

}

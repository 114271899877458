/* eslint-disable no-param-reassign */
/* eslint-disable max-len */

import { ref } from "vue";
import isEqual from "lodash/isEqual";

export const widgetLayout = ref();

export const setLayout = layout => {
  widgetLayout.value = layout;
};

export const updateOptions = (opts, name) => {
  const index = widgetLayout.value.findIndex(w => w.widget === name);
  widgetLayout.value[index].options = opts;
};

export const widgets = ref([]);

export const setWidgets = data => {
  widgets.value = data;
};

export const removeWidget = targetId => {
  const targetIndex = widgets.value.findIndex(w => w.id === targetId);
  widgets.value.splice(targetIndex, 1);
};

export const addWidget = item => {
  const nextY = widgets.value.length ? Math.max(...widgets.value.map(w => w.position.y)) + 1 : 0;
  const newWidget = {
    id: crypto.randomUUID(),
    name: item.val,
    header: item.label,
    position: { x: 0, y: nextY },
    size: { w: 1, h: 3 },
    mobileHeight: null,
  };
  
  widgets.value.push(newWidget);
};

export const layoutToWidgets = (data, available) => {
  const nextWidgets = data.map(item => {
    const [label] = available.find(([, widget]) => widget === item.widget) ?? [];
    const header = label ?? "Error!";
    return {
      id: crypto.randomUUID(),
      name: item.widget,
      header,
      position: { ...item.position },
      size: { w: item.size.x, h: item.size.y },
      mobileHeight: item.mobile_size,
      resizing: false,
      options: item.options,
    };
  });
  setWidgets(nextWidgets);
};

export const widgetsToLayout = data => {
  const layout = data.map(w => ({
    widget: w.name,
    position: { ...w.position },
    size: { x: w.size.w, y: w.size.h },
    mobile_size: w.mobileHeight,
    options: w.options,
  }));
  setLayout(layout);
};

export const updateWidgets = ({ layout, breakpoint }) => {
  if (breakpoint === "lg") {
    widgets.value.forEach(w => {
      const gridItem = layout.find(({ i }) => i === w.id);
      if (!gridItem) return;
      w.position = { x: gridItem.x, y: gridItem.y };
      w.size = { w: gridItem.w, h: gridItem.h };
    });
  } else {
    widgets.value.forEach(w => {
      const gridItem = layout.find(({ i }) => i === w.id);
      if (!gridItem) return;
      w.mobileHeight = gridItem.h;
    });
  }
};

export const stopResizing = () => widgets.value.forEach(w => (w.resizing = false));

export const updateWidgetOptions = layout => {
  widgets.value.forEach(w => {
    const match = layout.find(i => i.widget === w.name);
    if (!isEqual(match.options, w.options)) w.options = match.options;
  });
};

export const availableWidgets = ref([]);

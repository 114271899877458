document.addEventListener('DOMContentLoaded', function () {
  var cocoonElementCounter = 0;

  var createNewID = function () {
    return (new Date().getTime() + cocoonElementCounter++);
  };

  var newcontentBraced = function (id) {
    return '[' + id + ']$1';
  };

  var newcontentUnderscored = function (id) {
    return '_' + id + '_$1';
  };

  var newcontentData = function (id) {
    return "$1" + id;
  }

  var getInsertionNodeElem = function (insertionNode, insertionTraversal, thisNode) {
    if (!insertionNode) {
      return thisNode.parentNode;
    }

    if (typeof insertionNode === 'function') {
      if (insertionTraversal) {
        console.warn('association-insertion-traversal is ignored, because association-insertion-node is given as a function.');
      }
      return insertionNode(thisNode);
    }

    if (typeof insertionNode === 'string') {
      if (insertionTraversal) {
        return thisNode[insertionTraversal](insertionNode);
      } else {
        return insertionNode === 'this' ? thisNode : document.querySelector(insertionNode);
      }
    }
  };

  var cocoonDetach = function (node) {
    return node.parentElement.removeChild(node);
  };

  var cocoonGetPreviousSibling = function (elem, selector) {
    var sibling = elem.previousElementSibling;

    if (!selector) return sibling;

    while (sibling) {
      var match = sibling.matches(selector);
      if (match) return sibling;
      sibling = sibling.previousElementSibling;
    }
  };

  var openingTagFor = function (html) {
    const matches = html.trim().match(/<(\w+).*>/);
    return matches ? matches[1] : null;
  }

  var wrapperTagsFor = function (openingTag) {
    switch(openingTag) {
      case "tr":
        return ["<table>", "</table>"];
      case "td":
        return["<table><tr>", "</table></tr>"];
      case "li":
        return ["<ul>", "</ul>"];
      default:
        return ["", ""];
    }
  }

  var cocoonAddFields = function (e, target) {
    e.preventDefault();
    e.stopPropagation();

    var thisNode = target;
    var assoc = thisNode.getAttribute('data-association');
    var assocs = thisNode.getAttribute('data-associations');
    var content = thisNode.getAttribute('data-association-insertion-template');
    var insertionMethod = thisNode.getAttribute('data-association-insertion-method') || thisNode.getAttribute('data-association-insertion-position') || 'before';
    var insertionNode = thisNode.getAttribute('data-association-insertion-node');
    var insertionTraversal = thisNode.getAttribute('data-association-insertion-traversal');
    var insertAsHTML = thisNode.getAttribute("data-insert-as-html") || false;
    var count = parseInt(thisNode.getAttribute('data-count'), 10);
    var regexpBraced = new RegExp('\\[new_' + assoc + '\\](.*?\\s)', 'g');
    var regexpUnderscored = new RegExp('_new_' + assoc + '_(\\w*)', 'g');
    var regexpData = new RegExp('(data-\\S*-?)new_' + assoc, 'g');
    var newId = createNewID();
    var newContent = content.replace(regexpBraced, newcontentBraced(newId));
    var newContents = [];
    var originalEvent = e;

    if (newContent === content) {
      regexpBraced = new RegExp('\\[new_' + assocs + '\\](.*?\\s)', 'g');
      regexpUnderscored = new RegExp('_new_' + assocs + '_(\\w*)', 'g');
      regexpData = new RegExp('(data-\\S*-?)new_' + assocs, 'g');
      newContent = content.replace(regexpBraced, newcontentBraced(newId));
    }

    newContent = newContent.replace(regexpUnderscored, newcontentUnderscored(newId));
    newContent = newContent.replace(regexpData, newcontentData(newId));
    newContents = [newContent];

    count = (isNaN(count) ? 1 : Math.max(count, 1));
    count -= 1;

    while (count) {
      newId = createNewID();
      newContent = content.replace(regexpBraced, newcontentBraced(newId));
      newContent = newContent.replace(regexpUnderscored, newcontentUnderscored(newId));
      newContents.push(newContent);

      count -= 1;
    }

    var insertionNodeElem = getInsertionNodeElem(insertionNode, insertionTraversal, thisNode);

    if (!insertionNodeElem || (insertionNodeElem.length === 0)) {
      console.warn("Couldn't find the element to insert the template. Make sure your `data-association-insertion-*` on `link_to_add_association` is correct.");
    }

    newContents.forEach(function (node, i) {
      var contentNode = node;

      var beforeInsert = new CustomEvent('cocoon:before-insert', { bubbles: true, cancelable: true, detail: [contentNode, originalEvent] });
      insertionNodeElem.dispatchEvent(beforeInsert);

      if (!beforeInsert.defaultPrevented) {
        // allow any of the jquery dom manipulation methods (after, before, append, prepend, etc)
        // to be called on the node.  allows the insertion node to be the parent of the inserted
        // code and doesn't force it to be a sibling like after/before does. default: 'before'
        var htmlMapping = {
          before: 'beforebegin',
          prepend: 'afterbegin',
          append: 'beforeend',
          after: 'afterend',
        };
        var htmlMethod = htmlMapping[insertionMethod];

        // Inserting as HTML is more performant and may be required for certain elements (i.e. <td>s),
        // but it does not return a node reference. By default, convert HTML to DOM elements and
        // insertAdjacentElement so that the event callback gets a DOM reference.
        // Only insert as HTML if explicitly requested by data-insert-as-html
        if(insertAsHTML) {
          insertionNodeElem.insertAdjacentHTML(htmlMethod, contentNode); // has no return value
        } else {
          var openingTag = openingTagFor(contentNode);
          var wrapperTags = wrapperTagsFor(openingTag);
          contentNode = wrapperTags[0] + contentNode + wrapperTags[1];

          var wrapper = document.createElement("div");
          wrapper.innerHTML = contentNode;
          var elemToAdd = wrapper.querySelector(openingTag);
          elemToAdd.dataset["cocoonId"] = newId;
          var addedContent = insertionNodeElem.insertAdjacentElement(htmlMethod, elemToAdd);
        }

        var afterInsert = new CustomEvent('cocoon:after-insert', { bubbles: true, detail: [contentNode, originalEvent, addedContent] });
        insertionNodeElem.dispatchEvent(afterInsert);
      }
    });
  };

  var cocoonRemoveFields = function (e, target) {
    var thisNode = target;
    var wrapperClass = thisNode.getAttribute('data-wrapper-class') || 'nested-fields';
    var nodeToDelete = thisNode.closest('.' + wrapperClass);
    var triggerNode = nodeToDelete.parentNode;
    var originalEvent = e;

    e.preventDefault();
    e.stopPropagation();

    var beforeRemove = new CustomEvent('cocoon:before-remove', { bubbles: true, cancelable: true, detail: [nodeToDelete, originalEvent] });
    triggerNode.dispatchEvent(beforeRemove);

    if (!beforeRemove.defaultPrevented) {
      var timeout = triggerNode.getAttribute('data-remove-timeout') || 0;

      setTimeout(function () {
        if (thisNode.classList.contains('dynamic')) {
          cocoonDetach(nodeToDelete);
        } else {
          var hiddenInput = cocoonGetPreviousSibling(thisNode, 'input[type=hidden]');
          hiddenInput.value = '1';
          nodeToDelete.style.display = 'none';
        }
        var afterRemove = new CustomEvent('cocoon:after-remove', { bubbles: true, detail: [nodeToDelete, originalEvent] });
        triggerNode.dispatchEvent(afterRemove);
      }, timeout);
    }
  };

  document.addEventListener('click', function (e) {
    for (var target = e.target; target && target !== this; target = target.parentNode) {
      if (target.matches('.add_fields')) {
        cocoonAddFields(e, target);
        return;
      }
      if (target.matches('.remove_fields')) {
        cocoonRemoveFields(e, target);
        return;
      }
    }
  }, false);
});

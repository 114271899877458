import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1992f858"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-container w-100 d-flex flex-column align-items-center" }
const _hoisted_2 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "modal-body d-flex align-items-center position-relative gap-4 mb-3",
      style: _normalizeStyle($setup.uiState.create && { height: '550px' })
    }, [
      ($props.loading || !$setup.file)
        ? (_openBlock(), _createBlock($setup["Loading"], { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode($setup["Body"], { file: $setup.file }, null, 8 /* PROPS */, ["file"])
          ])),
      _createVNode($setup["Overlay"], {
        id: $setup.file ? $setup.file.id : null
      }, null, 8 /* PROPS */, ["id"])
    ], 4 /* STYLE */),
    (!$props.loading && $setup.file)
      ? (_openBlock(), _createBlock($setup["Footer"], {
          key: 0,
          file: $setup.file
        }, null, 8 /* PROPS */, ["file"]))
      : _createCommentVNode("v-if", true)
  ]))
}
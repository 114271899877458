<template>
  <div class="signature-overlay error text-danger">
    <div class="font-weight-bold">Error:</div>
    <div v-html="errorMessage"></div>
    <button type="button" class="btn btn-sm btn-light" @click="closeError">OK</button>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
    },
  },

  methods: {
    closeError() {
      this.$emit("closeError");
    },
  },

  computed: {
    errorMessage() {
      return this.errors.join("<br />");
    },
  },
};
</script>

import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard-widget__content" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "dashboard-widget-mobile" }
const _hoisted_4 = { class: "dashboard-widget-mobile__content" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([
      'dashboard-widget draggable',
      $props.editing ? 'dashboard-widget--editing draggable--sorting draggable__inner' : '',
    ])
    }, [
      _createVNode($setup["WidgetHeader"], {
        header: $props.widget.header
      }, null, 8 /* PROPS */, ["header"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "widget-html",
          innerHTML: $props.html
        }, null, 8 /* PROPS */, _hoisted_2)
      ])
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["WidgetHeader"], {
        header: $props.widget.header,
        isMobile: ""
      }, null, 8 /* PROPS */, ["header"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "widget-html",
          innerHTML: $props.html
        }, null, 8 /* PROPS */, _hoisted_5)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
<template>
  <Teleport to="body">
    <div
      :class="['offcanvas offcanvas-start', className && className]"
      tabindex="-1"
      :id="id"
      :aria-labelledby="titleRef.id"
      style="width: 450px"
    >
      <slot />
    </div>
  </Teleport>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  title: String,
  id: [String, Number],
  className: String,
});

const titleRef = ref({});
</script>

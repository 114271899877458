import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b885581"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex gap-3 h-100 justify-content-center align-items-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("button", {
      type: "submit",
      class: "btn btn-danger btn-lg",
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.fileStore.deleteAttachment($props.id)), ["prevent"]))
    }, " Confirm Delete "),
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-outline-dark btn-lg cancel",
      onClick: $setup.closeOverlay
    }, " Cancel ")
  ]))
}
<template>
  <Modal title="New Comment" id="newCommentModal">
    <div class="modal-body">
      <div id="comment-flash">
        <div v-if="flash_message" class="alert alert-warning">
          {{ flash_message }}
        </div>
      </div>
      <form>
        <div class="form-group">
          <label>Comment</label>
          <textarea
            id="comment_comment"
            rows="3"
            class="form-control"
            v-model="newComment.comment"
          ></textarea>
        </div>
        <div v-if="show_checkboxes">
          <div v-if="showPrivate" class="form-group form-check form-switch">
            <input
              type="checkbox"
              class="form-check-input"
              id="private"
              v-model="newComment.private"
            />
            <label class="form-check-label" for="private"
              >Make this an internal comment? It will be viewable by SFCTA staff only.</label
            >
          </div>

          <div v-if="showCommentEmail" class="form-group form-check form-switch">
            <input
              type="checkbox"
              class="form-check-input"
              id="send_email"
              v-model="newComment.send_email"
            />
            <label class="form-check-label" for="send_email"
              >Email this comment to project staff and subscribers?</label
            >
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        v-on:click="handleClose"
        data-bs-dismiss="modal"
      >
        Close
      </button>
      <button
        type="button"
        class="btn btn-primary"
        v-on:click="postComment"
        v-bind:disabled="disablePostButton"
      >
        {{ formSubmitCommentOptional ? "Submit" : "Post" }}
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from "../shared/modal/modal.vue";

export default {
  components: {
    Modal,
  },
  props: {
    tab_placement: String,
    sort: Number,
    user_is_staff: {
      type: Boolean,
      default: false,
    },
    flash_message: {
      type: String,
      default: null,
    },
    show_checkboxes: {
      type: Boolean,
      default: true,
    },
    force_comment_email: {
      type: Boolean,
      default: null,
    },
    force_private: {
      type: Boolean,
      default: null,
    },
    newCommentModal: Object,
    formSubmitCommentOptional: Boolean,
  },

  data() {
    return {
      newComment: {
        comment: "",
        private: this.force_private || false,
        tab_placement: this.tab_placement,
        sort: this.sort,
        send_email: this.force_comment_email || false,
      },
      user: { is_staff: this.user_is_staff },
    };
  },

  watch: {
    tab_placement(newTab) {
      this.newComment.tab_placement = newTab;
    },
    sort(newValue) {
      this.newComment.sort = newValue;
    },
  },

  computed: {
    showCommentEmail() {
      return this.force_comment_email == null;
    },
    showPrivate() {
      return this.user_is_staff && this.force_private == null;
    },
    newCommentDefaults() {
      return {
        comment: "",
        private: this.force_private || false,
        send_email: this.force_comment_email || false,
      };
    },
    disablePostButton() {
      return this.newComment.comment == "" && !this.formSubmitCommentOptional;
    },
    blankComment() {
      return {
        comment: "",
        private: this.force_private || false,
        tab_placement: this.tab_placement,
        sort: this.sort,
        send_email: this.force_comment_email || false,
      };
    },
  },

  methods: {
    postComment() {
      this.$emit("post-comment", {
        comment: this.newComment,
        indexPath: [this.newComment.sort],
      });
      this.resetComment();
      bootstrap.Modal.getInstance(document.querySelector("#newCommentModal")).hide();
    },
    resetComment() {
      this.newComment = Object.assign(this.newComment, this.newCommentDefaults);
    },
    handleClose() {
      this.resetComment();
      this.$emit("modal-close");
    },
  },
};
</script>

<template>
  <div class="d-flex w-100 my-5 gap-3 justify-content-center">
    <div class="spinner-grow text-primary spinner" role="status" />
    <div class="spinner-grow text-primary spinner" role="status" />
    <div class="spinner-grow text-primary spinner" role="status" />
  </div>
  <div v-if="showMessage" class="d-flex w-100 mt-3 justify-content-center lead">
    {{ message }}
  </div>
  <div v-else class="visually-hidden">
    {{ message }}
  </div>

  </template>;

<script setup>
import { computed } from "vue";

const props = defineProps(["message"]);
const message = computed(() => props.message || "Loading...");
const showMessage = !!props.message;

</script

<style scoped>
.spinner {
  width: 30px;
  height: 30px;
}
</style>

import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard-widget__content" }
const _hoisted_2 = { class: "dashboard-widget__content--error" }
const _hoisted_3 = { class: "dashboard-widget-mobile" }
const _hoisted_4 = { class: "dashboard-widget-mobile__content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([$setup.className, $props.editing ? $setup.editingClass : ''])
    }, [
      _createVNode($setup["WidgetHeader"], {
        header: $props.widget.header,
        isError: ""
      }, null, 8 /* PROPS */, ["header"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["ErrorBody"], {
            name: $props.widget.name,
            error: $props.error
          }, null, 8 /* PROPS */, ["name", "error"])
        ])
      ])
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["WidgetHeader"], {
        header: $props.widget.header,
        isMobile: "",
        isError: ""
      }, null, 8 /* PROPS */, ["header"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["ErrorBody"], {
          name: $props.widget.name,
          error: $props.error
        }, null, 8 /* PROPS */, ["name", "error"])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
<template>
  <button
    class="btn btn-primary mt-4"
    data-bs-toggle="offcanvas"
    data-bs-target="gallery-filters"
    @click.prevent="fileStore.fetchAttachments"
  >
    Update
  </button>
</template>

<script setup>
import { useFileStore } from "../../../stores";

const fileStore = useFileStore();
</script>

import { defineStore } from "pinia";
import { ref } from "vue";

export const useUIStateStore = defineStore("uiState", () => {
  // Session values; only updated on app mount
  const recordId = ref(null);
  const recordType = ref(null);
  const deliverableId = ref(null);
  const modalId = ref(null);
  const global = ref(false);
  const sidebar = ref(false);
  const user = ref(null);

  // Modal or detail pane state
  const index = ref(0);
  const overlay = ref(false);
  const create = ref(false);
  const modalRef = ref(null);
  const modalLoading = ref(false);

  // List/grid state
  const error = ref(null);
  const tabCountRef = ref(null);
  const listLoading = ref(true);
  const showList = ref(false);
  const showDetail = ref(false);

  return {
    index,
    overlay,
    create,
    error,
    modalRef,
    tabCountRef,
    listLoading,
    modalLoading,
    global,
    recordId,
    recordType,
    deliverableId,
    modalId,
    sidebar,
    user,
    showList,
    showDetail,
  };
});

export default useUIStateStore;

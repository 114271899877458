import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b4949e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-table-td" }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass($setup.className)
    }, _toDisplayString($setup.formatField($setup.props.field.field, $setup.props.record)), 3 /* TEXT, CLASS */),
    _createElementVNode("a", {
      href: $setup.props.record.link,
      class: "stretched-link"
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be670b4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-widget__content" }
const _hoisted_2 = {
  key: 1,
  class: "table-wrapper widget-table"
}
const _hoisted_3 = { class: "dashboard-widget-mobile" }
const _hoisted_4 = { class: "dashboard-widget-mobile__content" }
const _hoisted_5 = {
  key: 1,
  class: "widget-table table-wrapper mobile-table-wrapper"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([
      'dashboard-widget draggable',
      $props.editing && 'dashboard-widget--editing draggable--sorting draggable__inner',
    ])
    }, [
      _createVNode($setup["WidgetHeader"], {
        header: $props.widget.header,
        settings: $props.settings,
        onShowSettings: _cache[0] || (_cache[0] = $event => (_ctx.$emit('showSettings')))
      }, null, 8 /* PROPS */, ["header", "settings"]),
      _createElementVNode("div", _hoisted_1, [
        ($props.loading)
          ? (_openBlock(), _createBlock($setup["Loading"], { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode($setup["WidgetTable"], {
                size: $props.size,
                page: $props.page,
                id: $props.id,
                records: $props.records,
                fields: $props.fields,
                perPage: $props.perPage,
                breakpoint: $props.breakpoint,
                onSort: $setup.onSort
              }, null, 8 /* PROPS */, ["size", "page", "id", "records", "fields", "perPage", "breakpoint"])
            ]))
      ])
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["WidgetHeader"], {
        header: $props.widget.header,
        onShowSettings: _cache[1] || (_cache[1] = $event => (_ctx.$emit('showSettings'))),
        settings: $props.settings,
        isMobile: ""
      }, null, 8 /* PROPS */, ["header", "settings"]),
      _createElementVNode("div", _hoisted_4, [
        ($props.loading)
          ? (_openBlock(), _createBlock($setup["Loading"], { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode($setup["WidgetTable"], {
                size: $props.size,
                page: $props.page,
                id: $props.id,
                records: $props.records,
                fields: $props.fields,
                perPage: $props.perPage,
                breakpoint: $props.breakpoint,
                onSort: $setup.onSort
              }, null, 8 /* PROPS */, ["size", "page", "id", "records", "fields", "perPage", "breakpoint"])
            ]))
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
export default class Tables { // eslint-disable-line no-unused-vars

  initialize() {
    this.setTableHeaders();
  }

  bindListeners() {
    document.querySelectorAll("[data-expandable]").forEach((node) => {
      if(node.scrollHeight > node.clientHeight) {
        node.classList.add("overflow");
        node.addEventListener("click", this.toggleExpandable);
      }
    });
  }

  toggleExpandable = event => {
    const target = event.target.closest("[data-expandable]");
    if(!target) return;

    if(target.classList.contains("expanded")) {
      target.style.maxHeight = "";
      target.classList.remove("expanded");
    } else {
      const fullHeight = target.scrollHeight;
      target.style.maxHeight = fullHeight + "px";
      target.classList.add("expanded");
    }
  }

  setTableHeaders() {
    document.querySelectorAll("table.table-mobile").forEach((table) => {
      const headers = Array.from(table.querySelectorAll("thead th")).map((th) => {
        return th.textContent;
      });
      table.querySelectorAll("tbody tr").forEach((tr) => {
        tr.querySelectorAll("td").forEach((td, i) => {
          td.setAttribute("data-header", headers[i]);
        });
      });
    });
  }

}

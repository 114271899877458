import DOM from "../utilities/dom";
import SFCTAFormHelper from "../forms/sfcta_form_helper";

/**
 * @class StatusChanges
 * Manages status change buttons/controls
 */

export default class StatusChanges {
  initialize() {
    const csrfMeta = document.querySelector("meta[name=csrf-token]");
    this.authToken = csrfMeta ? csrfMeta.content : null;
  }

  bindListeners() {
    DOM.listen("[data-status-change]", "click", this.handleStatusChangeClick);
  }

  // Event Listeners

  /**
   * @callback handleStatusChangeClick
   * If status change requires a comment, sets up Comments app for status change comment
   * Else, if target form has status field, sets it and submits
   * Else, creates status change form and submits
   * @param {object} event - the click event
   */
  handleStatusChangeClick = event => {
    const clicked = event.target;

    const toStatus = clicked.dataset.statusChange;
    const modelName = clicked.dataset.statusChangeModel;
    const targetFormSelector = `.edit_${modelName}`;
    const targetForm = document.querySelector(targetFormSelector);
    const targetFormUrl = targetForm ? targetForm.action : window.location.href;

    if (clicked.dataset.statusChangeRequireComment) {
      targetForm
        ? this.submitWithCommentRequired(toStatus, targetForm, targetFormUrl)
        : this.setupFormSubmitComment(toStatus, targetFormUrl);
    } else if (targetForm) {
      const existingStatusField = document.querySelector(`#${modelName}_status`);
      existingStatusField
        ? (existingStatusField.value = toStatus)
        : this.addField(targetForm, `${modelName}[status]`, toStatus);
      targetForm.submit();
    } else {
      const form = this.createStatusUpdateForm(toStatus, targetFormUrl);
      form.submit();
    }
  };

  // Helper Functions

  /**
   * Asynchronously submits the target form before requesting a comment
   * @param {string} status - the status to be transitioned to
   */
  async submitWithCommentRequired(status, targetForm, targetFormUrl) {
    const response = await SFCTA.SFCTAFormHelper.submitAsync(targetForm);

    if (response.ok) {
      this.setupFormSubmitComment(status, targetFormUrl);
    }
  }

  /**
   * Sets up the page's Comments app for a form submit comment
   * Requires a comment be submitted before submitting the status change form
   * @param {string} status - the status to be transitioned to after the comment is created
   */
  setupFormSubmitComment(status, formUrl) {
    const form = this.createStatusUpdateForm(status, formUrl);
    SFCTA.CommentsApp.app._instance.ctx.$root.setupFormSubmitComment(
      form,
      form.querySelector("#comment-id-field"),
    );
  }

  /**
   * Creates a form to submit the status change
   * @param {string} status - the status to be transitioned to
   * @param {commentId} integer - the comment ID associated with this status change, if present
   * @return {element} - The newly created form element
   */
  createStatusUpdateForm(status, formUrl, commentId) {
    const form = document.createElement("form");
    form.action = `${formUrl}/update_status`;
    form.method = "POST";

    this.addField(form, "authenticity_token", this.authToken);
    this.addField(form, "status", status);
    this.addField(form, "comment_id", commentId, { id: "comment-id-field" });

    document.querySelector("body").appendChild(form);

    return form;
  }

  /**
   * Creates a hidden field for a form
   * @param {object} form - the form to which the field should be appended
   * @param {string} name - the name of the field
   * @param {string} value - the value of the field
   * @param {object} options - HTML options to be applied to the field
   * @return {object} - the newly appended field
   */
  addField(form, name, value, options = {}) {
    const field = document.createElement("input");
    field.type = "hidden";
    field.name = name;
    field.value = value;
    Object.keys(options).forEach(key => {
      field[key] = options[key];
    });

    form.appendChild(field);

    return field;
  }
}

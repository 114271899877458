<template>
  <button
    type="button"
    :class="className ? className : 'button-unstyled'"
    data-bs-toggle="offcanvas"
    :data-bs-target="selector"
  >
    <slot />
  </button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  id: [String, Number],
  className: [String, Object],
});

const selector = computed(() => `#${props.id}`);
</script>

<style scoped>
.button-unstyled {
  outline: 0;
  border: 0;
  appearance: none;
  background: none;
}
</style>

import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b9b8300"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form-overlay bg-white d-flex justify-content-center align-items-center"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["Settings"], {
          widget: $props.widget.name,
          onCancel: _cache[0] || (_cache[0] = $event => (_ctx.$emit('cancel'))),
          settings: $props.settings,
          values: $props.widget.options
        }, null, 8 /* PROPS */, ["widget", "settings", "values"])
      ]))
    : _createCommentVNode("v-if", true)
}
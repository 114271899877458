import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abe1432a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column gap-2 justify-content-center wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "d-block"
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex flex-column gap-4 justify-content-center w-100 wrapper"
}
const _hoisted_4 = { class: "d-flex justify-content-center mt-2" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isImage($props.file))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["Photo"], {
            isModal: "",
            isGallery: $setup.uiState.global,
            url: $props.file.attributes.url
          }, null, 8 /* PROPS */, ["isGallery", "url"]),
          ($props.file.attributes.description)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.description), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["Document"], {
            isModal: "",
            path: $props.file.attributes.icon_path
          }, null, 8 /* PROPS */, ["path"]),
          _createElementVNode("span", null, _toDisplayString($setup.description), 1 /* TEXT */)
        ])),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => ($setup.updateFile('prev'))),
        class: "button-unstyled prev",
        disabled: $setup.disablePrev
      }, [
        _createVNode($setup["PrevIcon"])
      ], 8 /* PROPS */, _hoisted_5),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = $event => ($setup.updateFile('next'))),
        class: "button-unstyled next",
        disabled: $setup.disableNext
      }, [
        _createVNode($setup["NextIcon"])
      ], 8 /* PROPS */, _hoisted_6)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
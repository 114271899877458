<template>
  <div class="detail-container w-100 d-flex flex-column align-items-center">
    <div
      class="modal-body d-flex align-items-center position-relative gap-4 mb-3"
      :style="uiState.create && { height: '550px' }"
    >
      <Loading v-if="loading || !file" />
      <div v-else>
        <Body :file="file" />
      </div>
      <Overlay :id="file ? file.id : null" />
    </div>
    <Footer v-if="!loading && file" :file="file" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Modal } from "../../../../shared/modal";
import Body from "../parts/body.vue";
import Footer from "../parts/footer.vue";
import { CreateForm } from "../../../form";
import { Loading } from "../../../../shared/loading";
import Overlay from "./overlay.vue";
import { useUIStateStore, useFileStore } from "../../../stores";

defineProps(["loading"]);

const fileStore = useFileStore();
const uiState = useUIStateStore();

const file = computed(() => fileStore.files[uiState.index]);
const title = computed(() => (uiState.create ? "Add Attachment" : file?.value?.attributes?.name));
</script>

<style scoped>
@import "../../../../shared/mixins.css";

.detail-container {
  container: detail / inline-size;
}

.modal-body {
  height: 550px;
  width: 100%;
  max-width: 1000px;
  justify-content: center;

  @mixin respond 475px, height, 350px;
  @mixin respond 1380px, flex-direction, column;
  @mixin respond 475px, justify-content, space-evenly;
}
</style>

/**
* @class AllocationRequestRecommendationsNewEdit
* Page-specific script for allocation_request_recommendations #new and #edit
*/

export default class AllocationRequestRecommendationsNewEdit {

  initialize() {
    this.fundShares = JSON.parse(document.querySelector("#allocation_request_recommendation_fund_share").dataset.lut);
    this.expirations = JSON.parse(document.querySelector("#allocation_request_recommendation_expiration_date").dataset.lut);
  }

  bindListeners() {
    // Set fund share on EP change
    SFCTA.DOM.listen("#allocation_request_recommendation_ep_number_id", "change", this.handleEpChange);

    // Set expiration on phase change
    SFCTA.DOM.listen("#allocation_request_recommendation_phase_id", "change", this.handlePhaseChange);
  }

  handleEpChange = event => {
    const selected = event.target.selectedOptions[0];
    const fundSourceId = selected.dataset["fundSourceId"];
    const value = this.fundShares[fundSourceId] || 0;
    const target = document.querySelector("#allocation_request_recommendation_fund_share");

    target.value = parseFloat(value);
  }

  handlePhaseChange = event => {
    const phase = event.target.value;
    const expiration = this.expirations[phase];

    document.querySelector("#allocation_request_recommendation_expiration_date").value = expiration;
  }

}

<template>
  <div>
    <div
      v-bind:id="commentSelectorID"
      class="d-flex justify-content-between py-3 pe-3"
      v-bind:class="[commentStyle, { 'comment--private': private }]"
      v-bind:style="indent"
    >
      <div class="flex-fill">
        <div class="d-flex justify-content-between mb-1">
          <div class="weak mediumtext" v-if="private">
            <span class="text-warning">Internal {{ commentType }}</span>
            <span v-html="commentableLink"></span>
            by {{ this.comment.user.name }}, {{ createdDateTime }}
          </div>
          <div class="weak mediumtext" v-else>
            {{ commentType[0].toUpperCase() + commentType.slice(1) }}
            <span v-html="commentableLink"></span>
            by {{ this.comment.user.name }},
            {{ createdDateTime }}
          </div>

          <div v-if="can_edit">
            <div class="btn-group" v-if="showEditingControls">
              <button
                type="button"
                class="btn btn-sm btn-outline-success"
                v-on:click="updateComment"
              >
                Save
              </button>
              <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="cancelEdit">
                Cancel
              </button>
            </div>

            <div class="btn-group" v-if="showControls">
              <button
                type="button"
                v-if="this.comment.replyable && depth < maxReplyDepth"
                class="btn btn-sm btn-outline-success"
                v-on:click="replyToComment"
              >
                Reply
              </button>

              <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                v-if="this.comment.editable"
                v-on:click="editing = true"
              >
                Edit
              </button>

              <button
                type="button"
                class="btn btn-sm btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#commentModal"
                v-if="this.comment.editable"
                v-on:click="removeComment"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div v-if="editing" class="form-group">
          <textarea rows="3" class="form-control" v-model="commentText"></textarea>
          <div v-if="showPrivate && !parent_comment_is_private" class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="privateEdit" v-model="private" />
            <label class="form-check-label" for="privateEdit"
              >Make this an internal comment? It will be viewable by SFCTA staff only.</label
            >
          </div>
        </div>

        <div v-else v-html="commentHTML"></div>
      </div>
    </div>

    <draggable
      v-model="comment.replies"
      v-bind:disabled="!sorting"
      v-on:start="localDragging = true"
      v-on:end="localDragging = false"
      v-if="comment.replies.length > 0"
      itemKey="id"
    >
      <template #item="{element, index}">
        <CommentThread
          v-bind:comment="element"
          v-bind:parent_comment_is_private="element.private"
          v-bind:sorting="sorting"
          v-bind:indexPath="indexPath.concat(index)"
          v-bind:user_is_staff="user_is_staff"
          v-bind:depth="depth + 1"
          v-bind:force_private="force_private"
          v-bind:force_comment_email="force_comment_email"
          v-on:remove-comment="passThroughRemove"
          v-on:update-comment="passThroughEdit"
          v-on:reply-to-comment="passThroughReply"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "CommentThread",
  components: {
    draggable,
  },
  props: {
    comment: Object,
    parent_comment_is_private: Boolean,
    depth: {
      type: Number,
      default: 0,
    },
    maxReplyDepth: {
      type: Number,
      default: 999,
    },
    indexPath: Array,
    sorting: {
      type: Boolean,
      default: false,
    },
    user_is_staff: {
      type: Boolean,
      default: false,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    show_commentable_link: {
      type: Boolean,
      default: false,
    },
    force_comment_email: {
      type: Boolean,
      default: null,
    },
    force_private: {
      type: Boolean,
      default: null,
    },
    can_edit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      date: this.comment.created_at,
      editable: this.comment.editable,
      replyable: this.comment.replyable,
      editing: false,
      commentText: this.comment.comment,
      private: this.comment.private || false,
      localDragging: this.dragging,
    };
  },

  mounted() {
    if (window && document && this.comment.scrollTo) {
      // get the position of the element based on the dimensions of the viewport
      const element = document.getElementById(this.commentSelectorID).getBoundingClientRect();
      // return if the comment is already in view
      if (element.top >= 0 && element.bottom <= window.innerHeight) return;
      // distance from the viewport + distance of the viewport from the top - height of the static nav bar
      const top = element.top + window.scrollY - 60;
      window.scroll({ top, behavior: "smooth" });
    }
    this.comment.scrollTo = false;
  },

  methods: {
    updateComment() {
      this.editing = false;
      this.$emit("update-comment", {
        comment: {
          id: this.comment.id,
          comment: this.commentText,
          private: this.private,
        },
        indexPath: this.indexPath,
      });
    },
    removeComment() {
      this.$emit("remove-comment", {
        commentID: this.comment.id,
        indexPath: this.indexPath,
      });
    },
    replyToComment() {
      this.$emit("reply-to-comment", {
        parentComment: {
          id: this.comment.id,
          text: this.commentText,
          html: this.commentHTML,
          indexPath: this.indexPath,
          replies: this.comment.replies,
          private: this.private,
        },
      });
    },
    cancelEdit() {
      // clicking the cancel button will set editing to false and the change
      // will happen so quickly that the delete button will take it's place and
      // register the click to delete the comment. This changes editing to false
      // after the event loop has completed all blocking tasks (such as a click)
      setTimeout(() => (this.editing = false), 0); // eslint-disable-line no-return-assign
    },
    // for any comments that are nested recursively inside this thread,
    // they need to be able to bubble up emissions to the top level comments
    // component
    passThroughRemove(event) {
      this.$emit("remove-comment", event);
    },
    passThroughEdit(event) {
      this.$emit("update-comment", event);
    },
    passThroughReply(event) {
      this.$emit("reply-to-comment", event);
    },
  },

  computed: {
    commentHTML() {
      return this.commentText.replaceAll("\n", "<br />");
    },
    createdDateTime() {
      return SFCTA.Formats.formatDatetime(this.date, false);
    },
    indent() {
      // bootstrap padding classes will overwrite any inline html styles,
      // so no padding was added on the right side using bootstrap classes
      // and the padding left was added in here. So at a depth of 0, there
      // should be 16px in (the amount of padding for a boostrap p-3 class)
      // and at each subsequent depth, we will get another 16px of left padding
      return { "padding-left": `${(this.depth + 1) * 16}px` };
    },
    commentType() {
      if (this.depth && this.depth > 0) {
        return "reply";
      }
      return "message";
    },
    commentStyle() {
      if (!this.sorting) {
        return null;
      }

      return ["comment--sorting", "move"];
    },
    commentSelectorID() {
      return `comment-${this.comment.id}`;
    },
    commentableType() {
      return this.comment.commentable_type;
    },
    commentablePath() {
      return this.comment.commentable_path;
    },
    commentableLink() {
      if (!this.show_commentable_link) return "";

      return this.commentableType != "Project"
        ? `on a <a href='${this.commentablePath}'>${this.commentableType}</a>`
        : "";
    },
    showPrivate() {
      return this.user_is_staff && this.force_private == null;
    },
    showCommentEmail() {
      return this.force_comment_email == null;
    },
    showControls() {
      return !this.editing && this.can_edit;
    },
    showEditingControls() {
      return this.editing;
    },
  },
};
</script>

import { createApp } from "vue";
import { Uid } from "../directives";

import Comments from "./comments/comments.vue";
import withTabFilter from "./comments/with-tab-filter";

/**
 * @class CommentsApp
 * Initializes and manages Vue comments app
 *
 * Currently, supports only one instance of comments on a given page.
 */
export default class CommentsApp {
  constructor() {
    this.app = null;
  }

  initialize() {
    if (this.renderTarget) {
      this.initializeCommentsApp();
    }
  }

  get renderTarget() {
    return document.querySelector("#comments-app");
  }

  bindListeners() {
    document.querySelectorAll("[data-form-submit-comment]").forEach(node => {
      node.addEventListener("click", this.setupFormSubmitComment);
    });
  }

  // Form comment listener

  setupFormSubmitComment = event => {
    event.preventDefault();

    const trigger = event.target;
    const form = trigger.closest("form");
    const commentField = form.querySelector("[data-comment-id-field]") || this.createCommentField(form);
    const optional = trigger.dataset.commentOptional || false;
    const message = trigger.dataset.commentMessage;

    this.app._instance.ctx.$root.setupFormSubmitComment(form, commentField, !optional, message);
  };

  createCommentField(form) {
    const commentField = document.createElement("input");
    commentField.name = "comment_id";
    commentField.dataset.commentIdField = true;
    commentField.type = "hidden";
    form.appendChild(commentField);

    return commentField;
  }

  initializeCommentsApp() {
    const props = JSON.parse(this.renderTarget?.getAttribute("data"));

    const app = createApp(props.tabbed ? withTabFilter(Comments) : Comments, props);

    app.config.compilerOptions = {
      whitespace: "preserve",
    };
    app.directive("uid", Uid);

    app.mount(this.renderTarget);

    this.app = app;
  }
}

<template>
  <div class="dashboard-top-bar d-flex justify-content-between">
    <div class="dashboard-top-bar__left">
      <h2>
        SFCTA Portal Dashboard
      </h2>
      <Dropdown buttonContent="add-icon" :showMenu="editing" id="widgets" />
    </div>
    <div class="d-flex align-items-center">
      <button class="btn btn-outline-dark btn-grid" @click="toggleEditMode">
        <GridIcon v-if="!editing" />
        <SaveIcon v-if="editing" />
      </button>
      <span
        id="dashboard-help-button"
        class="btn badge bg-info"
        role="button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#dashboard-help"
      >
        ?
      </span>
    </div>
  </div>
  <hr class="hr-primary m-0" />
</template>

<script setup>
import { inject } from "vue";
import Dropdown from "./dropdown.vue";
import Icons from "../../../shared/icons";
import { stopResizing, widgets, widgetsToLayout } from "../../store";

const GridIcon = Icons.Grid;
const SaveIcon = Icons.Save;

const editing = inject("editing");

const toggleEditMode = () => {
  if (!editing.value) {
    editing.value = true;
    return;
  }
  editing.value = false;
  stopResizing();
  widgetsToLayout(widgets.value);
};
</script>

<style>
.btn.btn-grid {
  padding: 6px;
  padding-inline: 8px;
}

#dashboard-help-button {
  font-size: 18px;
  font-weight: normal;
  padding: 8px;
  margin-top: 2px;
  width: 38px;
  margin-left: 5px;
}

.dashboard-top-bar {
  padding: 1rem;

  &__left {
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-inline-end: 10px;
    }
  }

  &__add-toggle {
    border: none;
    background: none;
    color: #363a40;
    position: relative;

    &.dropdown-toggle-no-caret {
      &::after {
        display: none !important;
      }
    }

    &:hover,
    &:focus-visible,
    &:focus {
      transition: none;
      background: none !important;
      color: #363a40 !important;
      border: none !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }
}
</style>

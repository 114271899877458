<template>
  <form
    ref="formRef"
    id="create-attachment"
    class="d-flex flex-column gap-4 h-100 w-75 m-auto justify-content-center"
  >
    <Input
      type="file"
      label="Upload file"
      labelLg
      aria-label="upload attachment file"
      :value="stringValue"
      @change="onChange"
    />
    <span class="weak"
      >Accepted files: doc, docx, gif, jpg, jpeg, key, kml, mpk, pdf, png, ppt, pptx, rtf, tif, txt,
      xer, xls, xlsm, xlsx, zip</span
    >
    <TextArea
      label="Description"
      labelLg
      aria-label="description"
      :value="formValues.description"
      @change="val => (formValues.description = val)"
    />
    <button
      type="submit"
      :disabled="!formValues.attachment"
      class="btn btn-outline-primary align-self-end"
      @click.prevent="updateAttachment"
    >
      Save
    </button>
  </form> </template
>;

<script setup>
import { reactive, ref, onMounted } from "vue";
import { Input, TextArea } from "../../shared/form";
import { useFileStore } from "../stores";

const fileStore = useFileStore();

const formRef = ref();

onMounted(() => {
  formRef.value.reset();
});

const formValues = reactive({
  attachment: null,
  description: null
});

const fileData = ref(null);

const stringValue = ref(null);

const updateAttachment = () => {
  fileStore.createAttachment({ ...formValues });
  formValues.attachment = null;
  formValues.description = null;
  formValues.purpose = null;
  stringValue.value = null;
};

const onChange = input => {
  formValues.attachment = input.files[0];
  stringValue.value = input.value;
};
</script>
;

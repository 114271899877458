import { createApp } from "vue";

import Group from "./signatures/group";

export default class SignaturesApp {
  initialize() {
    this.buildSignatures();
  }

  buildSignatures() {
    document.querySelectorAll("#signature-app").forEach((el, index) => {
      /* eslint-disable no-param-reassign */
      el.id = `signature-app_${index}`;

      this.buildSignatureApp(el);
    });
  }

  buildSignatureApp(el) {
    const data = JSON.parse(el?.getAttribute("data-signatures"));

    const props = Array.isArray(data) ? { data } : { data: [data] };

    const app = createApp(Group, props);

    app.mount(`#${el.id}`);
  }
}

import { createApp } from "vue";
import { Uid } from "../directives";

import Controller from "./dashboard/controller";

export default class DashboardApp {
  initialize() {
    if (this.renderTarget) {
      this.loadDashboardApp();
    }
  }

  get renderTarget() {
    return document.getElementById("dashboard-grid");
  }

  loadDashboardApp() {
    const app = createApp(Controller);

    app.config.compilerOptions = {
      whitespace: "preserve",
    };
    app.directive("uid", Uid);

    app.mount(this.renderTarget);
  }
}

import Sortable from "sortablejs";
import DOM from "../utilities/dom"

export default class Sortables { // eslint-disable-line no-unused-vars

  initialize() {
    this.initializeSortables();
  }

  bindListeners() {
    DOM.listen("[data-sort-toggle]", "click", this.toggleSorting);
  }

  initializeSortables() {
    const sortableBoxes = document.querySelectorAll('div[data-sortable-container]');

    for (let x = 0; x < sortableBoxes.length; x += 1) {
      const sortableBox = sortableBoxes.item(x);
      Sortable.create(sortableBox, {
        handle: '.sort-handle',
        draggable: '.sortable',
        onSort(event) {
          const allSortableBoxes = event.item.parentElement;
          const sortFields = allSortableBoxes.querySelectorAll('input[data-sort-order-field]');
          for (let y = 0; y < sortFields.length; y += 1) {
            sortFields.item(y).value = y;
            sortFields.item(y).dispatchEvent(new Event("change"));
          }
        },
      });
    }
  }

  toggleSorting = event => {
    const button = event.target;
    const containerSelector = button.dataset.sortToggle;
    const sortContainer = document.querySelector(containerSelector);
    const sortables = sortContainer.querySelectorAll("[data-sortable]");

    button.disabled = true;

    sortables.forEach(sortable => {
      sortable.querySelectorAll(".collapse").forEach(collapse => $(collapse).collapse("toggle"));
      sortable.classList.toggle("comment-block", "comment-block--sorting");
      sortable.querySelector(".sort-handle").classList.toggle("d-none");
    });

    button.classList.toggle("btn-primary", "btn-outline-primary");
    const buttonText = button.innerHTML;
    button.innerHTML = (button.dataset.buttonText || "Done Sorting");
    button.dataset.buttonText = buttonText;
  }

}

<template>
  <div>
    <label
      :class="[
        'form-label mb-1',
        labelLg && 'label-lg',
        hideLabel && 'visually-hidden',
      ]"
      :for="input.id"
      >{{ label }}</label
    >
    <input
      v-uid
      ref="input"
      :type="type"
      class="form-control"
      :aria-label="ariaLabel"
      :value="value"
      :placeholder="placeholder"
      @change.trim="$emit('change', input)"
    />
  </div> </template
>;

<script setup>
import { ref } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  label: String,
  labelLg: {
    type: Boolean,
    default: false,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  ariaLabel: String,
  value: String,
  placeholder: String,
});

defineEmits(["change"]);

const input = ref({});
</script>

<style scoped>
.form-label {
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
}
.label-lg {
  font-size: 0.95rem;
}
</style>

<template>
  <fieldset class="wrapper align-self-end">
    <legend class="legend mb-1">{{ label }}</legend>
    <div class="test d-flex flex-column flex-sm-row gap-2 mw-100">
      <input
        class="form-control"
        type="date"
        aria-label="start date"
        v-model="filters.created_at_start"
      />
      <span class="align-self-center legend">to</span>
      <input
        class="form-control"
        type="date"
        aria-label="end date"
        v-model="filters.created_at_end"
      />
    </div>
  </fieldset> </template
>;

<script setup>
import { useFilterStore } from "../../../stores";

defineProps({
  label: {
    type: String,
    default: "Include date range",
  },
});

const filters = useFilterStore();
</script>
;

<style scoped>
.legend {
  padding: 0;
  margin: 0;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
  line-height: var(--bs-body-line-height);
}

@container gallery (min-width: 995px) {
  .wrapper.filter-col {
    max-width: 34%;
  }
}

.global .wrapper,
.offcanvas .wrapper {
  width: 100%;
}

.global .d-flex {
  flex-direction: column !important;
}

@container gallery (max-width: 500px) {
  .test {
    flex-direction: column !important;
  }
}
</style>

import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b326189e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "button-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "mb-4"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.uiState.global)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["Disclosure"], {
          id: "gallery-filters",
          className: "btn btn-outline-primary filter-toggle"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["FilterIcon"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ViewButtons"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(['utility-wrapper d-flex gap-3 w-100', !$setup.canAdd && 'mt-3'])
        }, [
          ($setup.canAdd)
            ? (_openBlock(), _createBlock($setup["Add"], { key: 0 }))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["Search"]),
          _createVNode($setup["ViewButtons"])
        ], 2 /* CLASS */),
        _createVNode($setup["Filters"], { id: "filters-pane" })
      ]))
}
<template>
  <nav>
    <ul :class="listClasses">
      <li @click="onPageChange(1)" :class="['hide-xs', 'page-item', disablePrev && 'disabled']">
        <span class="page-link">
          «
        </span>
      </li>
      <li @click="onPageChange(page - 1)" :class="['prev', 'page-item', disablePrev && 'disabled']">
        <span class="page-link">
          ‹
        </span>
      </li>
      <li v-if="showPrevEllipses" class="page gap page-item disabled d-none d-sm-inline">
        <span class="page-link">
          …
        </span>
      </li>
      <li v-for="n in visiblePageNums" :key="n" @click="onPageChange(n)" :class="pageItemClass(n)">
        <span class="page-link">
          {{ n }}
        </span>
      </li>
      <li v-if="showNextEllipses" class="page gap page-item disabled d-none d-sm-inline">
        <span class="page-link">
          …
        </span>
      </li>
      <li @click="onPageChange(page + 1)" :class="['next', 'page-item', disableNext && 'disabled']">
        <span class="page-link">›</span>
      </li>
      <li
        @click="onPageChange(totalPages)"
        :class="['hide-xs', 'page-item', disableNext && 'disabled']"
      >
        <span class="page-link">»</span>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { getVisibleNums } from "./helpers";

const props = defineProps({
  page: {
    type: Number,
    default: 1,
  },
  totalPages: Number,
  visiblePages: {
    type: Number,
    default: 2,
  },
  positionAbsolute: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["pageChange"]);
const onPageChange = page => emit("pageChange", page);

const listClasses = computed(() => ({
  pagination: true,
  "w-100": true,
  "d-flex": true,
  "justify-content-center": true,
  "text-primary": true,
  "position-absolute": props.positionAbsolute,
  "start-0": true,
  "pagination-list": true,
}));
const pageItemClass = n => (n === props.page ? "page-item active" : "page-item d-none d-sm-inline");

const disablePrev = computed(() => props.page === 1);
const disableNext = computed(() => props.page === props.totalPages);

const visiblePageNums = computed(() =>
  getVisibleNums(props.page, props.totalPages, props.visiblePages),
);

const showPrevEllipses = computed(
  () => props.totalPages > props.visiblePages && !visiblePageNums.value.includes(1),
);
const showNextEllipses = computed(
  () => props.totalPages > props.visiblePages && !visiblePageNums.value.includes(props.totalPages),
);
</script>

<style scoped>
.pagination-list {
  bottom: 0.75rem;
  list-style: none;
  cursor: pointer;
}

.disabled {
  cursor: auto;
}

.page-link {
  padding: 9px 17px;
}

.page-item.active .page-link {
  color: #006c69;
  background-color: #f0fdfa;
  border-color: #a0d0cb;
}

@container gallery (max-width: 250px) {
  .hide-xs {
    display: none;
  }
}

@media all and (max-width: 375px) {
  .hide-xs {
    display: none;
  }
}
</style>
